<template>
  <div id="level-view" :style='levelViewStyle'>
    <Level
      :key='selectedLevelString'
      :levelId='selectedLevelString'
      :mapConfig='mapConfig'
    />
  </div>
</template>

<script>
import Level from '@/components/level/Level.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'LevelView',
  props: {
    mapConfig: {
      default: () => ({
        isDisabled: true,
        mode: 'levelSelect',
        iconSize: 100,
        width: 454,
        height: 454
      })
    }
  },
  components: {
    Level
  },
  computed: {
    ...mapGetters(['selectedLevelString']),
    levelViewStyle() {
      return {
        'min-width': `${this.mapConfig.width}px`,
        width: `${this.mapConfig.width}px`,
        height: `${this.mapConfig.height}px`,
        ...(this.mapConfig.isDisabled ? { 'pointer-events': 'none' } : null)
      }
    },
    levelString() {
      if (this.mode === 'OPM') {
        return ''
      }
      if (this.mode === 'levelSelect') {
        return this.selectedLevelString
      }
      return this.selectedLevelString
    }
  }
}
</script>

<style>
  #level-view {
    border: 1px black solid;
    position: relative;
    display: block;
  }
</style>
