import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import APIService from '@/API/API'
import App from '@/components/app/App.vue'
import router from '@/router'
import store from '@/store'
import Fragment from 'vue-fragment'
import { logEventTypes } from '@/store/modules/logEvents'
import mixins from '@/mixins'
import { routeNames } from './vars'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all'
import 'normalize.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Fragment.Plugin)

const api = new APIService()
Vue.prototype.$api = Vue.observable(api)

Vue.config.productionTip = false

Vue.mixin(mixins)

router.beforeEach(async (to, from, next) => {
  if (Vue.prototype.$api.user.isAuthenticated()) {
    if (store.getters.lastLogEvent.type === logEventTypes.FINISH_LEVEL_LOAD) {
      store.dispatch('resetLogData')
      next()
      // If entering Level from anywhere other than LevelSelect or if coming from another level
    }
    if ((to.name === routeNames.LEVEL && from.name !== routeNames.LEVEL_SELECT) || (from.name === routeNames.LEVEL)) {
      if (store.getters.isLogStateStale) {
        store.dispatch('enableLoadingDialog')
        store.dispatch('showMessage', 'Uploading log data...')
        try {
          const res = await store.dispatch('uploadLogData')
          console.log('main upload log data')
          console.log(res)
          await store.dispatch('resetLogData')
        } catch (error) {
          console.error(error)
          store.dispatch('showMessage', `There was an error uploading the log data: ${error.message}. Please try again.`)
        } finally {
          setTimeout(() => store.dispatch('showMessage', 'Log data uploaded successfully.'), 500)
          await store.dispatch('disableLoadingDialog')
        }
      }
      next()
    }
  }
  if (to.name === routeNames.CREATE_ACCOUNT && !store.getters.accountDidConsent) {
    next({ name: routeNames.PRIVACY_AGREEMENT })
  }
  if (from.name === routeNames.PRIVACY_AGREEMENT && !store.getters.accountDidConsent && to.name === routeNames.LANDING) {
    store.dispatch('showMessage', 'You must agree to the PARALLEL PRIVACY AND DATA POLICY in order to make an account. If you do not want your data collected, you can play the game without creating an account by clicking “PLAY” below.')
  }
  next()
})

const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
})

app.$mount('#app')
