<template>
  <v-card min-width='50' class="timer-ctr" @mousemove='mouseMove' @mouseup='mouseUp'>
    <v-card-text>
      <div class="timer-text">
        <span :style="`color: #${this.color}`">{{this.timerText}}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Timer',
  data() {
    return {
      draggedEl: null,
      startCell: null,
      startTime: 0,
      seconds: 0,
      target: 900,
      color: '525555',
      timer: null
    }
  },
  computed: {
    ...mapGetters(['isDraggingElement', 'select', 'draggedElementPosition']),
    timerText() {
      const minutesNum = Math.floor(this.seconds / 60)
      let minutesString = minutesNum.toString()
      if (minutesNum < 10) {
        minutesString = `0${minutesString}`
      }
      const secondsNum = this.seconds % 60
      let secondsString = secondsNum.toString()
      if (secondsNum < 10) {
        secondsString = `0${secondsString}`
      }
      return `${minutesString}:${secondsString}`
    }
  },
  methods: {
    ...mapActions(['setIsDraggingElement', 'showMessage', 'draw', 'setDraggedElementPosition']),
    mouseUp(e) {
      this.startCell = this.draggedElementPosition
      const el = this.select(e.y, e.x)
      this.draggedEl = el
      this.draggedEl.coordinates = [e.y, e.x]
      if (!this.isSimulationActive && this.isDraggingElement) {
          this.setIsDraggingElement(false)
          this.returnDraggedEl()
          this.showMessage('Elements must be placed on path!')
      }
      this.draw()
    },
    mouseMove(e) {
      const { y, x } = e
      const el = this.select(y, x)
      // If an element is being dragged
      if (this.isDraggingElement) {
        this.draggedEl = el
        this.draggedEl.coordinates = [y, x]
      }
      this.draw()
    },
    returnDraggedEl() {
      this.draggedEl.cell = this.startCell
      this.startCell = null
      this.setDraggedElementPosition(null)
      this.resetDraggedEl()
    },
    resetDraggedEl() {
      this.draggedEl = null
    }
  },
  mounted() {
    let time = new Date()
    this.startTime = Math.round(time.getTime() / 1000)
    this.timer = setInterval(() => {
      time = new Date()
      const currentTime = Math.round(time.getTime() / 1000)
      this.seconds = currentTime - this.startTime
      if (this.seconds > this.target) {
        this.color = 'FF0000'
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
  .timer-ctr {
    position: absolute;
    top: 50px;
    right: 3px;
    min-width: 50px;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
  }
  /*.dialog-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }*/
  .timer-title {
    padding-top: 0.5rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }
  .timer-text {
    font-weight: 700;
    font-size: 1rem;
  }

</style>
