<template>
    <div v-if="isWaitingDialogActive" class="dialog-background">
      <div class="waiting-text">
        {{getWaitingText}}
      </div>
    </div>
  </template>

  <script>
  import { mapGetters, mapActions } from 'vuex'
  import { routeNames } from '../../vars'

  export default {
    name: 'WaitingDialog',
    methods: {
      ...mapActions([

      ]),
      handleReturnToLevelSelect() {
        this.$router.push({ name: routeNames.LEVEL_SELECT })
      },
    },
    computed: {
      ...mapGetters([
        'isReflectionDialogActive',
        'retrieving',
        'isSimulationActive',
        'isSimulationWaiting'
      ]),
      isWaitingDialogActive() {
        if (!this.isSimulationActive && !this.isReflectionDialogActive && this.retrieving) {
          return true
        }
        if (this.isSimulationWaiting) {
          return true
        }
        return false
      },
      getWaitingText() {
        return 'Analyzing Player Trace...'
      }
    }
  }
  </script>

  <style scoped>
    .dialog-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
      backdrop-filter: blur(2px);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
    }
    .waiting-text {
      font-size: 2rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: white;
    }
  </style>
