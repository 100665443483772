import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import message from './modules/message'
import tutorial from './modules/tutorial'
import level from './modules/level'
import levelSelect from './modules/levelSelect'
import map from './modules/map'
import simulation from './modules/simulation'
import reflection from './modules/reflection'
import element from './modules/element'
import loadingDialog from './modules/loadingDialog'
import log from './modules/log'
import account from './modules/account'
import environment from './modules/environment'
import opm from './modules/opm'
import viewportOpm from './modules/viewportOpm'
import community from './modules/community'

Vue.use(Vuex)

const logSession = createPersistedState({
  paths: ['log'],
  key: 'ParallelLogState',
  storage: window.sessionStorage
})

// Create store
const store = new Vuex.Store({
  modules: {
    account,
    element,
    environment,
    level,
    levelSelect,
    loadingDialog,
    log,
    map,
    message,
    opm,
    reflection,
    simulation,
    tutorial,
    viewportOpm,
    community,
  },
  plugins: [logSession]
})

export default store
