<template>
  <div class="auth-background">
    <v-card min-width='700' class='privacy-agreement-ctr'>
      <v-container class='card-ctr'>
        <div class='privacy-content' @scroll='onScroll'>
          <AgreementContent/>
        </div>
        <br>
        <v-checkbox
          v-model='didConsent'
          label='I agree to the privacy policy'
          hint='You must read the entire policy to agree'
          persistent-hint
          :disabled='isConsentDisabled'
          required
          ref='consentCheckbox'
          id='consentCheckbox'
        ></v-checkbox>
        <br>
        <v-btn color='primary'
          :disabled='!didConsent'
          to='/create-account'
          ref='continueButton'
          class='continue-btn'
          >Continue</v-btn>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AgreementContent from './AgreementContent.vue'

export default {
  name: 'PrivacyAgreement',
  data() {
    return {
      isConsentDisabled: true,
      scrollMargin: 5 // flexibility/give in our check to see if the user has scrolled all the way down the agreement
    }
  },
  components: {
    AgreementContent
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      console.log(`scrollTop:${scrollTop}   clientHeight:${clientHeight}   scrollHeight:${scrollHeight}`) // debugging from scroll issues
      if (scrollTop + clientHeight >= scrollHeight - this.scrollMargin) {
        this.isConsentDisabled = false
      }
    }
  },
  computed: {
    ...mapGetters(['accountDidConsent']),
    didConsent: {
      get() {
        return this.accountDidConsent
      },
      set(value) {
        this.$store.commit('setAccountValueByKey', { key: 'didConsent', value })
      }
    }
  },
}
</script>

<style>
.v-messages__message {
  color: red;
}
.privacy-agreement-ctr {
  width: 800px;
  background-color: white;
  padding: 1.2rem;
  width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.privacy-content {
  background-color: rgb(243, 242, 242);
  height: 500px;
  padding: 1rem;
  overflow-y: scroll;
}
</style>
