function getDefaultState() {
    return {
        // not being used
        viewPorts: {
            logId: null,
            recommendation: null, // Suggestion or Recommendation
            currentEventId: null,
        },
        tracesToShow: [0],
        nodeColors: { good: 'green', bad: 'red', ignore: '#e0e0e0' },
        markAsRecommendation: [],
        allRecommendedPlayersForCurrentEvent: []
    }
}
const state = getDefaultState()
const getters = {
    playerTraces: (state, getters) => getters.traces,
    currentScreenshotURL: (state, getters) => (logIndex, eventIndex) => {
        console.log(getters.traces)
        console.log(logIndex)
        console.log(getters.traces[logIndex])
        let base_url = 'https://parallel-player-screenshots.s3.us-west-1.amazonaws.com/'
        base_url += getters.traces[logIndex].log_id
        base_url += '/'
        base_url += getters.traces[String(logIndex)].events[eventIndex].screenshot
        console.log(base_url)
        return base_url
    },
    tracesToShow: (state) => state.tracesToShow,
    nodeColors: (state) => state.nodeColors,
    markAsRecommendation: (state) => state.markAsRecommendation,
    indexForLogId: (state, getters) => (logId) => {
        for (let i = 0; i < getters.traces.length; i++) {
            if (getters.traces[i].log_id === logId) {
                return i
            }
        }
        console.log('not found')
        return null
    },
    indexForEventId: (state, getters) => (logIndex, eventId) => {
        for (let eventIndex = 0; eventIndex < getters.traces[logIndex].events.length; eventIndex++) {
            if (getters.traces[logIndex].events[eventIndex].id === eventId) {
                return eventIndex
            }
        }
        return null
    },
    getRecommendedPlayersForCurrentEvent: (state) => state.allRecommendedPlayersForCurrentEvent,
}
const actions = {
    emptyTracesToShow: ({ commit }) => {
        commit('emptyTracesToShow')
    },
    addToShowTrace: ({ commit }, id) => {
        commit('addToShowTrace', id)
      },
    addToMarkAsRecommendation: ({ commit }, eventId) => {
        commit('addToMarkAsRecommendation', eventId)
    },
    emptyMarkedRecommendations: ({ commit }) => {
        commit('emptyMarkedRecommendations')
    },
    setRecommendedPlayersForCurrentEvent: ({ commit }, players) => {
        commit('setRecommendedPlayersForCurrentEvent', players)
    }
}
const mutations = {
    emptyTracesToShow: (state) => {
        state.tracesToShow = [0]
    },
    emptyMarkedRecommendations: (state) => {
        state.markAsRecommendation = []
    },
    addToShowTrace: (state, id) => {
        if (!state.tracesToShow.includes(id)) {
        state.tracesToShow.push(id)
        } else {
            console.log('Not adding', id, 'since it already exists')
        }
      },
    addToMarkAsRecommendation: (state, eventId) => {
        state.markAsRecommendation.push(eventId)
    },
    setRecommendedPlayersForCurrentEvent: (state, players) => {
        state.allRecommendedPlayersForCurrentEvent = players
    }
}
export default {
    state,
    getters,
    actions,
    mutations,
  }
