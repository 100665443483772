<template>
  <div class="community-dialog">
    <div v-if="isIndicatorActive" class="community-dialog-indicator">
      <div class="community-indicator-icon">
        <img :src=getIconImage() style="width:50px;height:50px" />
      </div>
      <div class="community-indicator-text">
        {{getIndicatorText}}
      </div>
    </div>
    <div v-if="isCommunityDialogActive" class="community-dialog-outer">
      <div class="community-content">
        <div class="community-header">
          <div class="community-header-icon">
            <img :src=getIconImage() style="width:50px;height:50px" />
          </div>
          <div class="community-header-title">
            {{getTitle}}
          </div>
        </div>
        <div class="community-body">
          {{getSubtitle}}
          <br />
          <br />
          {{getText}}
          <br />
          <br />
          {{getSuggestionFromOtherPlayer}}
        </div>
        <div class="community-media">
          <div class="community-name">
            {{getPlayerName}}
          </div>
          <img class="community-img" v-bind:src=this.getImgUrl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CommunityDialog',
  methods: {
    ...mapActions([
      'deactivateCommunityDialog'
    ]),
    getIconImage() {
      let img = ''
      if (this.communityContent.icon !== '') {
        img = require(`../../assets/icons/hintActive${this.communityContent.icon}.png`)
      } else {
        img = require('../../assets/icons/hint.png')
      }
      return img
    },
    getIndicatorImage() {
      const img = require('../../assets/icons/hint.png')
      return img
    }
  },
  computed: {
    ...mapGetters([
      'communityContent',
      'isCommunityDialogActive',
      'isReflectionDialogActive',
      'isTutorialActive',
      'imageByName',
      'retrieving',
      'traceData',
      'linkElements',
      'hasBoardChanged',
      'isSimulationActive',
      'badCount'
    ]),
    getTitle() {
      return this.communityContent.title
    },
    getSubtitle() {
      return this.communityContent.subtitle
    },
    getText() {
      return this.communityContent.text
    },
    getPlayerName() {
      return this.communityContent.name
    },
    getMedia() {
      return this.communityContent.media
    },
    getImgUrl() {
      const url = this.getMedia
      return url
      // return 'https://parallel-player-screenshots.s3.us-west-1.amazonaws.com/dce7bb00-da7e-4d62-a2e5-0bfc5e6b228d/0_252b0b3d-2bb2-4a1f-a2d0-b9e29aa80425.png'
    },
    getSuggestionFromOtherPlayer() {
      return this.communityContent.suggestion_from_recommened_player
    },
    isIndicatorActive() {
      /* if (this.traceData && !this.hasBoardChanged && !this.isSimulationActive && !this.isReflectionDialogActive && !this.retrieving) {
        if (this.isCommunityDialogActive) {
          return false
        }
        if (this.badCount > 0) {
          return true
        }
      } */
      return false
    },
    getIndicatorText() {
      return `${this.getSubtitle} Click the Community Button to review.`
    },
  },
}
</script>

<style scoped>
.community-dialog {
  background-color: white;
}
.community-dialog-outer {
    position: absolute;
    right: 0px;
    bottom: 100px;
    background-color: white;
    width: 33%;
    height: 65%;
  }
  .community-content {
    text-align: left;
    margin: auto;
    width: 95%;
    height: 95%;
    font-size: 1em;
    font-weight: 350;
  }
  .community-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 600;
    height: 8%;
  }
  .community-header-icon {
    width: 7%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .community-header-title {
    width: 93%;
    float: left;
  }
  .community-body {
    float: top;
    margin-top: 25px;
    width: 100%;
    height: 32%;
    border: 5px solid white;
    box-sizing: border-box;
    overflow-y:auto;
    font-size:1em;
  }
  .community-media {
    margin: auto;
    background-color: #628184;
    width: 100%;
    height: 60%;
  }
  .community-name {
    width:100%;
    height:10%;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
  }
  .community-img {
    width:90%;
    height:90%;
    object-fit: contain;
  }
  .community-dialog-indicator {
    position: absolute;
    right: 0px;
    bottom: 100px;
    background-color: white;
    width: 33%;
    height: 5%;
    font-size: .8em;
    font-weight: 350;
  }
  .community-indicator-icon {
    width: 6%;
    height: 100%;
    float: left;
  }
  .community-indicator-text {
    text-align: center;
    width: 94%;
    height: 100%;
    margin: auto;
  }
</style>
