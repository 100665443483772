import aws from '@/auth/aws'
import { elementType } from '@/vars'
import Link from '@/elements/Link'

export const traceColors = [
  {
    node: 'darkorange',
    edge: 'orange'
  },
  {
    node: 'darkred',
    edge: 'red'
  },
  {
    node: 'darkgreen',
    edge: 'green'
  }
]

export const graphColors = {
  selected: {
    node: {
      start: 'gray',
      success: 'green',
      failure: 'red'
    },
    edge: 'gray'
  },
  unselected: {
    node: {
      start: 'lightgray',
      success: 'lightgray',
      failure: 'lightgray'
    },
    edge: 'lightgray'
  }
}

/* eslint-disable no-shadow */
function getDefaultState() {
  return {
    selectedSnapshot: {
      suggestions: []
    },
    traces: [],
    selectedLogIdIndex: 0
  }
}

const state = getDefaultState()

const getters = {
  selectedSnapshot: (state) => state.selectedSnapshot,
  traces: (state) => state.traces,
  isLoadingTraces: (state) => state.isLoadingTraces,
  selectedLogIdIndex: (state) => state.selectedLogIdIndex,
}

const actions = {
  clearUserElements: async ({ getters, dispatch }) => {
    Promise.all(getters.userPlacedElements.map(async (el) => dispatch('removeElement', el)))
    Promise.all(getters.linkElements.map(async (el) => dispatch('removeElement', el)))
  },
  setSelectedLogIdIndex: ({ commit, }, logIdIndex) => {
    commit('setSelectedLogIdIndex', logIdIndex)
  },
  redrawLevel: async ({ getters, dispatch }) => {
    await dispatch('clearUserElements')
    const boardState = getters.selectedSnapshot.absolute_board_state
    if (boardState) {
      const components = []
      for (const id of Object.keys(boardState)) {
        const values = boardState[id]
        components.push({
          id,
          scale: getters.scale.board,
          transform: getters.transform,
          type: values.type,
          cell: [values.element_x, values.element_y],
          userPlaced: true,
          spec: values.status
        })
      }
      await dispatch('createLoadImageElements', { elementData: components, isBoardElement: true })
      for (const id of Object.keys(boardState)) {
        const values = boardState[id]
        const linkId = values.link
        if (linkId) {
          const sourceEl = getters.selectByID(linkId)
          const targetEl = getters.selectByID(id)
          if (sourceEl.id && targetEl.id) {
              const linkData = {
                id: 'abc123',
                sourceEl,
                targetEl,
                isBoardElement: false,
                isLinkElement: true,
                scale: getters.scale.board,
                type: elementType.LINK,
                spec: 'active',
                transform: getters.transform
              }
              dispatch('addElement', new Link(linkData))
            }
          }
        }
      dispatch('draw')
    }
  },
  setSelectedSnapshot: async ({ commit, getters, dispatch }, { logIdIndex, eventIndex, childIndex }) => {
    commit('setSelectedLogIdIndex', logIdIndex)
    const snapshot = childIndex ? getters.traces[logIdIndex].events[eventIndex].children[childIndex] : getters.traces[logIdIndex].events[eventIndex]
    commit('setSelectedSnapshot', snapshot)
    await dispatch('redrawLevel')
  },
  getSetTraces: async ({ commit, dispatch }, index) => {
    console.log(`getSetTraces: ${index}`)
    try {
      const { data: { traces } } = await aws.getTraces(index)
      console.log(traces)
      // const { test: { testData } } = await aws.hello()
      if (traces) {
        console.log('setting traces')
        commit('setTraces', traces)
        await dispatch('setSelectedSnapshot', { logIdIndex: 0, eventIndex: 1 })
      } else {
        dispatch('showMessage', `No play session found. Please play level ${getters.selectedLevelString} and return to this screen.`)
      }
    } catch (e) {
      dispatch('showMessage', e)
    }
  },
}

const mutations = {
  setSelectedLogIdIndex: (state, logIdIndex) => {
    state.selectedLogIdIndex = logIdIndex
  },
  setSelectedSnapshot: (state, payload) => {
    state.selectedSnapshot = payload
  },
  setTraces: (state, traces) => {
    state.traces = traces
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
