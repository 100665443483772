/* eslint-disable no-shadow */
import elh from '@/store/modules/elementHelpers'
import { IDGenerator, alphabet } from '@/IDGenerator'
import * as cloneDeep from 'lodash/cloneDeep'
import * as sortBy from 'lodash/sortBy'
import { logEventTypes } from '@/store/modules/logEvents'
import { dispatch } from 'd3'
import { actionType, elementType } from '../../vars'

const threadColors = {
  1: 'yellow',
  2: 'orange',
  3: 'magenta',
  4: 'red',
  5: 'green'
}

// TODO: make random
const idGenerator = IDGenerator(6, alphabet)

let arrows = []

const getDefaultState = () => ({
  elements: [],
  elementsCache: [],
  images: [],
  isMakingLink: false,
  isTrackHighlighted: false,
  highlightedTrack: null,
})

const state = getDefaultState()

const getters = {
  elements: (state) => {
    // TODO: this comparison is brittle - thread just happens to be last
    // Update so that links have lower z index
    const compare = (a, b) => {
      if (!a.name && !(a.data.isLinkElement || b.data.isLinkElement)) return 0
      if (a.isTrackElement) return -1
      if (b.isTrackElement) return 1
      if (a.data.isLinkElement) return -1
      if (b.data.isLinkElement) return 1
      if (a.name < b.name) {
        return -1
      } if (a.name > b.name) {
        return 1
      }
      return 0
    }

    // TODO: fix this. Elements should be sorted by desired render order.
    const elements = state.elements.sort(compare)
    return elements
  },
  elementsCache: (state) => state.elementsCache,
  images: (state) => state.images,
  imageByName: (state) => (name) => state.images.find((img) => img.name === name),
  userPlacedElements: (state, getters) => getters.elements.filter((el) => el.userPlaced),
  // TODO: The filtering and naming conventions need to be improved. Pretty hard to reason about right now.
  boardElements: (state) => state.elements.filter((el) => !!el.isBoardElement && !el.isLinkElement),
  boardTrackElements: (state, getters) => getters.boardElements.filter((el) => el.isTrackElement),
  boardImageElements: (state, getters) => getters.boardElements.filter((el) => el.isBoardImageElement),
  guiElements: (state) => state.elements.filter((el) => el.isGuiElement && el.type !== elementType.MOUSE),
  guiImageElements: (state, getters) => getters.guiElements.filter((el) => !el.isPathElement),
  guiPathElements: (state, getters) => getters.guiElements.filter((el) => el.isPathElement),
  signalElements: (state, getters) => getters.boardElements.filter((el) => el.type === elementType.SIGNAL),
  hintElements: (state, getters) => getters.boardElements.filter((el) => el.type === elementType.HINT),
  linkElements: (state, getters) => getters.elements.filter((el) => el.isLinkElement),
  allIDs: (state) => state.elements.map((el) => el.id),
  isMakingLink: (state) => state.isMakingLink,
  isTrackHighlighted: (state) => state.isTrackHighlighted,
  select: (state, getters) => (y, x, debug = false) => {
    if (debug) console.log(`selecting (${y}, ${x})`)
    // Check for gui elements first
    let element = getters.guiElements.find((el) => el.detectCollisionByPoint(y, x))
    // If no gui elements, transform to board coordinates and check those
    if (!element) {
      element = getters.hintElements.find((el) => el.detectCollisionByPoint(y, x, true))
    }
    // If no hint elements, transform to board coordinates and check those
    if (!element) {
      element = getters.boardImageElements.find((el) => el.detectCollisionByPoint(y, x))
    }
    return element
  },
  selectByCoords: (state, getters) => (y, x, debug = false) => {
    const elements = getters.boardImageElements
    let element = null
    for (let i = 0; i < elements.length; i++) {
      element = elements[i]
      if (element.data.y === y && element.data.x === x) {
        break
      }
    }
    return element
  },
  selectByID: (state, getters) => (id) => getters.elements.find((el) => el.id === id),
  // NOTE: this only works with board elements right now
  selectNewestByType: (state, getters) => (type) => {
    if (!type) return null
    const sorted = sortBy(getters.boardElements, 'created')
    return sorted.length ? sorted.find((el) => el.type === type.toLowerCase()) : null
  },
  isElementPartOfExistingLink: (state, getters) => (element) => {
    let flag = false
    getters.linkElements.forEach((le) => {
      if (le.sourceEl.id === element.id || le.targetEl.id === element.id) {
        flag = true
      }
    })
    return flag
  },
  highlightedTrack: (state) => state.highlightedTrack,
}

const actions = {
  createLoadPathElements: async ({ dispatch }, { elementData }) => {
    const pathElements = elementData.map((d) => {
      const data = d
      // TODO: this is brittle, but it works
      return elh.createPathElementFromData(data)
    })
    pathElements.forEach((el) => dispatch('addElement', el))
  },
  setIsMakingLink: ({ commit }, flag) => {
    commit('setIsMakingLink', flag)
  },
  createLoadImageElements: async ({ dispatch, getters }, { elementData, isBoardElement }) => {
    const imageElements = await Promise.all(elementData.map(async (d) => {
      const data = d
      data.isBoardElement = isBoardElement
      if (data.type === elementType.LINKS_INDICATOR) {
        data.onMouseDownAction = async () => {
          if (getters.shouldCheckTutorialRequiredAction) {
            const res = await dispatch('checkTutorialRequiredAction', { type: actionType.TOGGLE_LINKS, target: elementType.LINKS_INDICATOR })
            if (!res) return false
          }
          await dispatch('addLogEvent', { type: logEventTypes.TOGGLE_LINKS, target: elementType.LINKS_INDICATOR })
          dispatch('toggleLinksVisibility')
          dispatch('draw')
          return true
        }
      }
      if (data.type === elementType.THREAD) {
        const threadIdx = data.color
        data.onMouseOverAction = async () => {
          // The thread index is stored in the color attribute
          if (getters.shouldCheckTutorialRequiredAction && !getters.isTrackHighlighted) {
            const res = await dispatch('checkTutorialRequiredAction', { type: actionType.HIGHLIGHT_TRACK, target: elementType.THREAD })
            if (!res) return
          }
          if (!getters.isTrackHighlighted) {
            dispatch('addLogEvent', { type: logEventTypes.HIGHLIGHT_TRACK, value: true, index: threadIdx })
          }
          dispatch('highlightTrack', { fillStyle: threadColors[threadIdx.toString()], threadIdx })
          state.highlightedTrack = threadIdx
          dispatch('draw')
        }
        data.onMouseOutAction = async () => {
          dispatch('unhighlightTrack')
          dispatch('draw')
          dispatch('addLogEvent', { type: logEventTypes.HIGHLIGHT_TRACK, value: false, index: threadIdx })
        }
      }
      if (!data.id) {
        data.id = idGenerator.next().value
      }
      return elh.createImageElementFromData(data)
    }))
    const boundImageElements = await Promise.all(imageElements.map(async (el) => {
      const element = el
      let image = getters.imageByName(element.name)
      if (!image) {
        image = await elh.createImageByName(element.name)
        dispatch('addImage', image)
      }
      // For elements with active and inactive images, set primaryImage attribute to active and secondaryImage attribute to inactive
      if (element.type === elementType.SIGNAL) {
        element.primaryImage = getters.imageByName('signalActive')
        element.secondaryImage = getters.imageByName('signalInactive')
      } else if (element.type === elementType.SEMAPHORE) {
        element.primaryImage = getters.imageByName('semaphoreActive')
        element.secondaryImage = getters.imageByName('semaphoreInactive')
      } else if (element.type === elementType.TRASH) {
        element.primaryImage = getters.imageByName('trashActive')
        element.secondaryImage = getters.imageByName('trashInactive')
      } else if (element.type === elementType.LINKS_INDICATOR) {
        element.primaryImage = getters.imageByName('linksIndicatorInactive')
        element.secondaryImage = getters.imageByName('linksIndicatorActive')
      } else if (element.type === elementType.HINT) {
        element.primaryImage = getters.imageByName('hint')
        element.secondaryImage = getters.imageByName('hintAccurate')
        element.tertiaryImage = getters.imageByName('hintInaccurate')
      } else {
        element.primaryImage = image
      }
      return el
    }))
    boundImageElements.forEach((el) => dispatch('addElement', el))
  },
  createLoadImageElementFromExisting: async ({ dispatch, getters }, { existingElement, event }) => {
    const data = {
      id: idGenerator.next().value,
      isBoardElement: true,
      primaryImage: existingElement.primaryImage,
      secondaryImage: existingElement.secondaryImage,
      scale: getters.scale.board,
      userPlaced: true,
      type: existingElement.type,
      spec: existingElement.spec,
      transform: getters.transform
    }
    const newElement = existingElement.create(data)
    newElement.coordinates = [event.y, event.x]
    newElement.snapToGrid()
    dispatch('addElement', newElement)
  },
  beginLink: async ({ dispatch, getters }, { element, event }) => {
    if (getters.shouldCheckTutorialRequiredAction) {
      const res = await dispatch('checkTutorialRequiredAction', { type: actionType.BEGIN_LINK, target: element.type })
      if (!res) return null
    }
    dispatch('setIsMakingLink', true)
    const mouseElementData = {
      id: idGenerator.next().value,
      coords: [event.y, event.x],
      isBoardElement: false,
      scale: getters.scale.board,
      userPlaced: true,
      type: elementType.MOUSE,
      isLinkElement: false,
      spec: 'inactive',
      transform: getters.transform
    }
    // Create mouse element, which is just a stand-in - it won't be added to the main element list
    // It will be replaced by the linkEndElement
    const mouseElement = elh.createPathElementFromData(mouseElementData)
    // Add mouse element? This will ensure its transform is updated
    dispatch('addElement', mouseElement)
    const linkStartData = {
      id: idGenerator.next().value,
      sourceEl: element,
      targetEl: mouseElement,
      isBoardElement: false,
      isLinkElement: true,
      scale: getters.scale.board,
      type: elementType.LINK,
      spec: 'active',
      transform: getters.transform
    }
    const linkElement = elh.createPathElementFromData(linkStartData)
    dispatch('addElement', linkElement)
    dispatch('addLogEvent', { type: logEventTypes.BEGIN_LINK, element })
    return linkElement
  },
  createHint: async ({ dispatch, getters }, data) => {
    const hintElementData = {
      id: idGenerator.next().value,
      sourceEl: data.link.sourceEl,
      targetEl: data.link.targetEl,
      coords: [
        data.link.targetEl.coordinates[0] / 2 + data.link.sourceEl.coordinates[0] / 2,
        data.link.targetEl.coordinates[1] / 2 + data.link.sourceEl.coordinates[1] / 2
      ],
      cell: [
        Math.floor(data.link.targetEl.cell[0] / 2 + data.link.sourceEl.cell[0] / 2),
        Math.floor(data.link.targetEl.cell[1] / 2 + data.link.sourceEl.cell[1] / 2)
      ],
      isBoardElement: true,
      isLinkElement: false,
      userPlaced: false,
      type: elementType.HINT,
      spec: 'inactive',
      transform: getters.transform,
      content: data.content,
      index: data.index
    }
    hintElementData.onMouseDownAction = async () => {
      dispatch('setCommunityContent', hintElementData.content)
      dispatch('activateHint', hintElementData.index)
      dispatch('activateCommunityDialog')
    }
    const hintElement = await elh.createImageElementFromData(hintElementData)
    hintElement.primaryImage = getters.imageByName(`hintActive${hintElement.data.content.icon}`)
    hintElement.secondaryImage = getters.imageByName(`hintInactive${hintElement.data.content.icon}`)
    dispatch('addElement', hintElement)
    dispatch('draw')
    // dispatch('addLogEvent', { type: logEventTypes.ADD_ELEMENT, hintElement })
    return hintElement
  },
  activateHint: async ({ getters }, index) => {
    const hints = getters.hintElements
    for (let i = 0; i < hints.length; i++) {
      hints[i].spec = (i === index) ? 'active' : 'inactive'
    }
    dispatch('draw')
  },
  addElement: async ({ getters, commit, dispatch }, element) => {
    if (getters.shouldCheckTutorialRequiredAction) {
      const res = await dispatch('checkTutorialRequiredAction', { type: actionType.ADD_ELEMENT, target: element.type })
      if (!res) return
    }
    commit('addElement', element)
    if (element.userPlaced) {
      dispatch('setTestHasBeenRun', false)
      dispatch('setBoardChanged', true)
      if (![elementType.MOUSE, elementType.LINK].includes(element.type) && !getters.isSimulationActive) {
        dispatch('addLogEvent', { type: logEventTypes.ADD_ELEMENT, element })
      }
      dispatch('draw')
    }
  },
  async removeElement({ dispatch, commit, getters }, element) {
    // LOGEVENT remove element
    // Check if non-link element is part of any links
    const links = getters.linkElements.filter((le) => le.sourceEl.id === element.id || le.targetEl.id === element.id)
    const linkData = {
      num: links.length
    }
    if (links.length) {
      links.forEach(async (l) => {
        commit('removeElement', l)
        await dispatch('addLogEvent', { type: logEventTypes.REMOVE_LINK, link: l })
      })
    }
    const elementData = {
      id: element.id,
      type: element.type
    }
    commit('removeElement', element)
    if (getters.shouldCheckTutorialRequiredAction) {
      // TODO: even when successful, element takes too long to appear on board
      const res = await dispatch('checkTutorialRequiredAction', { type: actionType.ADD_ELEMENT, target: element.type })
      if (!res) return {}
    }
    return { elementData, linkData }
  },
  addImage({ commit }, image) {
    commit('addImage', image)
  },
  bindScale({ commit }, scale) {
    const { board, gui } = scale
    if (board && gui) {
      commit('bindScale', scale)
    } else {
      console.error('Scale object must have board and gui properties.')
    }
  },
  bindTransform({ commit }, transform) {
    commit('bindTransform', transform)
  },
  cacheElements({ commit }) {
    commit('removeAllCachedElements')
    commit('cacheElements')
  },
  loadElementsFromCache({ commit }) {
    commit('removeAllElements')
    commit('loadElementsFromCache')
  },
  destroyElements({ commit }) {
    commit('destroyElements')
  },
  serializeComponents: async ({ getters }) => {
    const target = {}
    // eslint-disable-next-line no-return-assign
    getters.boardImageElements.map((el) => el.serialize()).forEach((i) => target[i.id] = i)
    return target
  },
  highlightTrack: ({ commit }, payload) => {
    commit('highlightTrack', payload)
  },
  unhighlightTrack: ({ commit }) => {
    commit('unhighlightTrack')
  }
}

const mutations = {
  addElement(state, element) {
    state.elements.push(element)
  },
  removeElement(state, element) {
    const idx = state.elements.map((el) => el.id).indexOf(element.id)
    state.elements.splice(idx, 1)
  },
  addImage(state, image) {
    state.images.push(image)
  },
  bindScale(state, scale) {
    state.elements.forEach((el) => {
      const element = el
      element.scale = element.isBoardElement ? scale.board : scale.gui
    })
  },
  bindTransform(state, transform) {
    state.elements.forEach((el) => {
      const element = el
      element.transform = transform
    })
  },
  cacheElements(state) {
    state.elements.forEach((el) => {
      const obj = cloneDeep(el)
      state.elementsCache.push(obj)
    })
    const links = state.elementsCache.filter((el) => el.isLinkElement)
    // Must reset component elements in links to new cached elements because they are deep clones
    // This is to ensure elements moved after cached elements loaded will move their links too
    links.forEach((l) => {
      const sourceEl = state.elementsCache.find((el) => el.id === l.sourceEl.id)
      const targetEl = state.elementsCache.find((el) => el.id === l.targetEl.id)
      l.sourceEl = sourceEl
      l.targetEl = targetEl
    })
  },
  removeAllElements(state) {
    let i = state.elements.length
    while (i >= 0) {
      i -= 1
      state.elements.splice(i, 1)
    }
  },
  removeAllCachedElements(state) {
    let i = state.elementsCache.length
    while (i >= 0) {
      i -= 1
      state.elementsCache.splice(i, 1)
    }
  },
  loadElementsFromCache(state) {
    const elementsCache = state.elementsCache
    elementsCache.forEach((el) => {
      state.elements.push(el)
    })
  },
  destroyElements(state) {
    Object.assign(state, getDefaultState())
  },
  highlightTrack(state, { fillStyle, threadIdx }) {
    // TODO: add log event
    const numTrackElements = state.elements.filter((el) => el.isTrackElement && el.color.includes(threadIdx)).length
    let numTracksSeen = 0
    state.isTrackHighlighted = true
    state.elements.forEach((el) => {
      if (el.isTrackElement && el.color.includes(threadIdx)) {
        if (numTracksSeen === numTrackElements - 1) {
          el.displayArrows = true
        }
        el.clearArray = false
        el.fillStyle = fillStyle
        arrows.push(el.getTrackModifiers)
        el.setArrowsArr = arrows
        numTracksSeen += 1
      }
    })
  },
  unhighlightTrack(state) {
    state.isTrackHighlighted = false
    state.elements.forEach((el) => {
      if (el.isTrackElement) {
        el.displayArrows = false
        el.clearArray = true
        el.fillStyle = 'black'
        el.setArrowsArr = []
      }
    })
    arrows = []
  },
  setIsMakingLink(state, flag) {
    state.isMakingLink = flag
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
