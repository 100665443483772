/* eslint-disable no-param-reassign */
/* eslint-disable radix */

/**
 *    Script:         solutionChecker.js
 *    Description:    Solution checker logic for parallel
 *                    simulation function should be passed a 'board'
 *                    and what mode to run in
 *
 *    Author:         Boyd Fox                    Date: 3/4/2020
 *    Notes:
 */

import { ReflectionFormatter } from '@/reflectionFormatter/ReflectionFormatter'
import { IDGenerator, alphabet } from '@/IDGenerator'
import config from '@/config.json'
import * as seedrandom from 'seedrandom'
import { threadMovementLogic } from './componentLogic'
import { getThreadsToMove, getComponents, shuffle } from './boardUtils'
import {
  addFailures, completionCheck, failureCheck, exportAllGoalStatus, getFailureReason
} from './goals'

import { modes, movements, components } from './boardEnums'
import { elementType } from '../vars'

const reflectionFormatter = new ReflectionFormatter()

const createNewBoardObject = (board) => {
  const newBoard = JSON.parse(JSON.stringify(board))
  const newThreads = getComponents(newBoard.components, components.THREAD)
  const newPickups = getComponents(newBoard.components, components.PICKUP)
  let index = 0
  // add any needed fields here
  for (index = 0; index < newThreads.length; index += 1) {
    newBoard.components[newThreads[index]].stopped = false
    newBoard.components[newThreads[index]].delivered = 0
    newBoard.components[newThreads[index]].missed = 0
    newBoard.components[newThreads[index]].payload = []
    newBoard.components[newThreads[index]].starvation = 0
    newBoard.components[newThreads[index]].budget = 0
    newBoard.components[newThreads[index]].totalWait = 0
  }

  for (index = 0; index < newPickups.length; index += 1) {
    newBoard.components[newPickups[index]].available = 1
  }

  return newBoard
}

/**
 * loop() is the functin which simulates a board state to completion
 * returns a result object which has the list of generated actions
 * and information on the final result
 */
export const loop = async (board, threads, deliveries, idGenerator, rng, movementType = movements.RANDOM) => {
  // the goals of the level
  const goals = board.metadata.goal.struct.required
  // the object we'll return once the sim is done
  const data = { success: false, actions: [] }
  // tracks time for the sim
  let timestep = 0
  // tracks if the simulation failed
  const failureInfo = { failure: false, cause: '' }
  // array of threads that need to be moved on a given timestep
  let threadsToMove = []
  // indexer variable for for loops
  let index = 0

  /** main loop for running the sim until:
   * the goals are completed
   * it fails
   * the sim takes too long
   */

  const maxTime = (board.map.length * board.map[0].length)
    * config.solutionChecker.timeStepMultiplier
  const maxStarvation = (board.map.length * board.map[0].length)
    * config.solutionChecker.starvationMultiplier

  while (!completionCheck(board)
    && !failureCheck(board, goals, threads, failureInfo)
    && timestep < maxTime) {
    /* for (let iB = 0; iB < threads.length; iB += 1) {
      board.components[threads[iB]].starvation += 1
    } */

    if (movementType !== movements.DEFAULT) {
      threadsToMove = getThreadsToMove(
        board,
        threads,
        maxStarvation,
        config.solutionChecker.threadBudget,
        rng
      )
    } else {
      threadsToMove = threads
    }

    shuffle(threadsToMove, rng)
    for (index = 0; index < threadsToMove.length; index += 1) {
      const thread = board.components[threadsToMove[index]]
      if (!thread.stopped) {
        if (thread.cell == null) {
          return new Error('Thread has no defined cell.')
        }

        const result = threadMovementLogic(
          data,
          board,
          threads,
          thread,
          threads.indexOf(threadsToMove[index]),
          timestep,
          idGenerator,
        )
        if (result && result.stack && result.message) {
          return data
        } if (result && result.failure) {
          failureInfo.failure = result.failure
          break
        }
      }
    }

    // reset busy status on delivery points
    for (index = 0; index < deliveries.length; index += 1) {
      board.components[deliveries[index]].busy = false
    }

    timestep += 1
  }

  // get final success state
  const success = completionCheck(board)
  // create final action
  data.actions.push({
    id: idGenerator.next().value,
    type: 'X',
    timestep,
    component: {
      id: '1001',
      type: elementType.THREAD,
    },
    mutations: [{
      key: 'success',
      value: success,
    }],
  })

  data.success = success
  data.cause = getFailureReason(board,
    goals,
    maxTime,
    timestep)

  data.success = completionCheck(board)
  data.timestep = timestep
  data.ticks = timestep
  data.events = data.actions.length
  data.idle = 0
  for (let i = 0; i < threads.length; i++) {
    const thread = board.components[threads[i]]
    data.idle += thread.totalWait
  }
  return data
}

/**
 * simulation() is the main function for running the sim
 * needs a board object and a mode
 */
export const simulation = async (board, mode = modes.DEFAULT) => {
  const idGenerator = IDGenerator(config.solutionChecker.idSize, alphabet)
  let movementType = ''
  const orgBoard = JSON.parse(JSON.stringify(board))
  let tBoard = null
  let threads = null
  let deliveries = null
  let budget = 0
  let attempts = 0
  let result = {}
  let rng = seedrandom('parallelRNGSeed01')

  try {
    // basic board file validation
    if (orgBoard == null) {
      throw Error('Board object invalid or null.')
    }

    if (orgBoard.metadata == null) {
      throw Error('Metadata missing.')
    }

    if (orgBoard.map == null
      || orgBoard.map.length < 1
      || orgBoard.map[0].length < 1) {
      throw Error('Map data missing.')
    }

    if (orgBoard.components == null) {
      throw Error('Component data missing.')
    }

    if (getComponents(orgBoard.components, components.THREAD).length < 1) {
      throw Error('No threads found.')
    }

    if (
      orgBoard.metadata.goal == null
      || orgBoard.metadata.goal.struct == null
      || orgBoard.metadata.goal.struct.required == null
      || orgBoard.metadata.goal.struct.required.length < 1
    ) {
      throw (new Error('No goals found.'))
    }

    addFailures(
      getComponents(orgBoard.components, components.THREAD),
      orgBoard.metadata.goal.struct.required,
      (board.map.length * board.map[0].length)
      * config.solutionChecker.starvationMultiplier
    )
    const threadCount = getComponents(board.components, components.THREAD).length
    switch (mode) {
      case modes.ABSOLUTE:
        budget = 1
        movementType = movements.DEFAULT
        break

      case modes.TEST:
        budget = 1
        movementType = movements.RANDOM
        rng = seedrandom()
        break

      case modes.SUBMIT:
        budget = config.solutionChecker.submitBudget + threadCount + 1
        movementType = movements.RANDOM
        break

      default:
        budget = 10000
        movementType = movements.RANDOM
        break
    }

    /* eslint no-await-in-loop: "off" */

    while (attempts < budget) {
      attempts += 1
      if (attempts === budget && mode === modes.SUBMIT) {
        movementType = movements.DEFAULT
      }

      tBoard = createNewBoardObject(orgBoard)
      threads = getComponents(tBoard.components, components.THREAD)
      deliveries = getComponents(tBoard.components, components.DELIVERY)
      result = await loop(tBoard, threads, deliveries, idGenerator, rng, movementType)
      if (result && result.message && result.stack) {
        throw (result)
      } else if (modes.SUBMIT && !result.success) {
        break
      }
    }

    result.attempts = attempts
    result.ticks = result.actions[result.actions.length - 1].timestep
    // result.idle =
    result.goalStatus = exportAllGoalStatus(tBoard, result.cause)
    result.content = reflectionFormatter.formatContent(orgBoard.metadata.goal, result.goalStatus, orgBoard, mode)
    console.log(result)
    return result
  } catch (err) {
    result.error = err
    throw err
  }
}
