<template>
  <v-card :loading='loading'>
    <v-card-title class='opm-view-card-title'>Session Metrics</v-card-title>
    <v-card-text class='opm-view-card-content metrics-ctr'>
      <div><span class='snapshot-datum'>Class Average Test/Submits:</span> {{avgEvents}}</div>
      <div><span class='snapshot-datum'>Class Average Efficiency:</span> {{avgTicks}}</div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'SessionMetrics',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    avgEvents() {
      if (!this.traces.length) return 0
      let sum = 0
      this.traces.forEach((t) => {
        sum += t.events.length
      })
      return sum / this.traces.length
    },
    avgTicks() {
      if (!this.traces.length) return 0
      let sum = 0
      let length = 0
      this.traces.forEach((t) => {
        t.events.forEach((e) => {
          if (typeof e.ticks === 'number') sum += e.ticks
        })
        length += t.events.length
      })
      return sum / length
    }
  },
}
</script>

<style>
  .metrics-ctr {
    height: 150px;
  }
</style>
