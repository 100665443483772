import _axios from 'axios'
import config from '@/config.json'

// TODO: switch back to ternary for master branch
export const BASE_URL = config.aws.devURL
// export const BASE_URL = process.env.NODE_ENV === 'development' ? config.aws.devURL : config.aws.prodURL
const axios = _axios.create({})
axios.defaults.baseURL = BASE_URL

export default axios
