/* eslint-disable no-shadow */

const getDefaultState = () => ({
  communityContent: {
    icon: '',
    title: 'Community Default',
    subtitle: 'Default Subtitle',
    text: 'Default text, you should not be seeing this. Try again and alert  if issue persists.',
    suggestion_from_recommened_player: 'Default stuggestion, you should not be seeing this.',
    name: 'Default Player',
    media: 'https://www.reinforcedesigns.com/onlinemin/default-img/empty1.png'
  },
  contentArray: [],
  initialIndex: 0,
  goodCount: 0,
  badCount: 0,
  isCommunityDialogActive: false
})

const state = getDefaultState()
const getters = {
  communityContent: (state) => state.communityContent,
  contentArray: (state) => state.contentArray,
  initialIndex: (state) => state.initialIndex,
  goodCount: (state) => state.goodCount,
  badCount: (state) => state.badCount,
  isCommunityDialogActive: (state) => state.isCommunityDialogActive,
}
const actions = {
  buildCommunityContent: async ({ state, commit, rootState, rootGetters }) => {
    const userTrace = rootGetters.traceData[0]
    let key = ''
    let value = ''
    let logId = ''
    const counts = { good: 0, bad: 0 }
    const contentArray = []
    const consumed = []
    const linkEls = rootGetters.linkElements
    for (let i = userTrace.events.length - 1; i >= 0; i--) { // go from back to front
      const event = userTrace.events[i]
      if (event.move_classification !== 'ignore') {
        for (let j = 0; j < linkEls.length; j++) {
          const link = linkEls[j]
          if (link.sourceEl.data.cell[0] === event.signal_x
           && link.sourceEl.data.cell[1] === event.signal_y
           && link.targetEl.data.cell[0] === event.connected_x
           && link.targetEl.data.cell[1] === event.connected_y) {
            if (consumed.includes(link.sourceEl.data.id)) {
              break
            }
            if (event.move_classification === 'bad') {
              commit('setInitialIndex', contentArray.length)
              counts.bad += 1
            } else {
              counts.good += 1
            }
            const content = {
              icon: (event.move_classification === 'good') ? 'Accurate' : 'Inaccurate',
              title: 'Community',
              subtitle: event.top_text,
              text: '',
              suggestion_from_recommened_player: '',
              name: '',
              media: '',
              element: link.data.id
            }
            key = Object.keys(event.recommended_players)[0]
            value = event.recommended_players[key]
            content.text = event.for_me_to_think
            content.suggestion_from_recommened_player = event.suggestions_from_other_players[key]
            for (let l = 0; l < rootGetters.traceData.length; l++) {
              const trace = rootGetters.traceData[l]
              if (trace.log_id === key) {
                logId = trace.log_id
                content.name = `Player ${l}'s Solution`
                for (let m = 0; m < trace.events.length; m++) {
                  const e = trace.events[m]
                  if (e.id === value) {
                    content.media = `https://parallel-player-screenshots.s3.us-west-1.amazonaws.com/${logId}/${e.screenshot}`
                    break
                  }
                }
                break
              }
            }
            contentArray.push(content)
            consumed.push(link.sourceEl.data.id)
            break
          }
        }
      }
    }
    if (contentArray.length > 0) {
      commit('setCommunityContent', contentArray[state.initialIndex])
      console.log(contentArray)
    } else {
      contentArray.push({
        element: 'aaaaak',
        icon: 'Accurate',
        media: 'https://parallel-player-screenshots.s3.us-west-1.amazonaws.com/b6040382-973d-434c-8d69-ffe682aa06ed/143_196ac785-808b-4a4a-bc43-abb339e117e7.png',
        name: 'Placeholder Name',
        subtitle: 'Placeholder Subtitle',
        suggestion_from_recommened_player: 'Placeholder suggestion',
        text: 'Placeholder text',
        title: 'Community'
      })
      counts.good += 1
      commit('setCommunityContent', contentArray[0])
      console.log('No content to display.')
    }
    commit('setContentArray', contentArray)
    commit('setCounts', counts)
  },
  setCommunityContent: async ({ commit }, communityContent) => {
    commit('setCommunityContent', communityContent)
  },
  hintSetCommunityContent: async (icon, title, subtitle, text, suggestion_from_recommened_player, media) => {
    const content = {
      icon,
      title,
      subtitle,
      text,
      suggestion_from_recommened_player,
      media,
    }
    this.setCommunityContent(content)
  },
  clearCommunityContent: async ({ commit }) => {
    const content = { icon: '', title: '', subtitle: '', text: '', suggestion_from_recommened_player: '', media: '' }
    commit('setCommunityContent', content)
  },
  activateCommunityDialog: ({ commit }) => {
    commit('activateCommunityDialog')
  },
  deactivateCommunityDialog: ({ commit }) => {
    commit('deactivateCommunityDialog')
  }
}
const mutations = {
  setContentArray: (state, payload) => {
    state.contentArray = payload
  },
  setCommunityContent: (state, payload) => {
    if (payload == null) {
      console.log('Attempted to set null payload')
      return
    }
    state.communityContent.icon = payload.icon
    state.communityContent.title = payload.title
    state.communityContent.subtitle = payload.subtitle
    state.communityContent.text = payload.text
    state.communityContent.suggestion_from_recommened_player = payload.suggestion_from_recommened_player
    state.communityContent.name = payload.name
    state.communityContent.media = payload.media
  },
  setInitialIndex: (state, payload) => {
    state.initialIndex = payload
  },
  setCounts: (state, payload) => {
    state.goodCount = payload.good
    state.badCount = payload.bad
  },
  activateCommunityDialog: (state) => {
    state.isCommunityDialogActive = true
  },
  deactivateCommunityDialog: (state) => {
    state.isCommunityDialogActive = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
