<template>
  <v-navigation-drawer
    v-model="drawerLocal"
    absolute
    right
    temporary
    width='350'
    height='auto'
    id='nav-drawer'
    :style="`margin-top: 48px`"
  >
    <template #prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Authentication</v-list-item-title>
          <v-list-item-subtitle>{{$api.user.getEmail()}}</v-list-item-subtitle>
          <v-btn class='nav-btn' color='warning' @click='signOut'>Log Out</v-btn>
          <v-btn class='nav-btn' color='success' @click='getIdToken'>Get IdToken</v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Activity</v-list-item-title>
          <v-list-item-subtitle>{{getTitle()}}</v-list-item-subtitle>
          <v-btn class='nav-btn' width='10' color='primary' @click='joinActivity'>Join New Activity</v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'
import aws from '@/auth/aws'
import { routeNames } from '../../vars'

export default {
  name: 'AuthDrawer',
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    drawerLocal: {
      get() {
        return this.drawer
      },
      set(val) {
        this.$emit('update:drawer', val)
      }
    },
  },
  methods: {
    ...mapActions(['showMessage', 'disableLoadingDialog']),
    getTitle() {
      return this.$api.player.getCurrentActivityValueByKey('title') || 'Information not available'
    },
    getIdToken() {
      aws.getIdToken()
        .then((idToken) => {
          navigator.clipboard.writeText(idToken).then(() => {
            this.showMessage('IdToken has been copied to clipboard.')
          }).catch((err) => {
            this.showMessage(`Failed to copy IdToken: ${err.message}`)
          })
        })
        .catch((err) => {
          this.showMessage(`An error has occurred: ${err.message}`)
        })
        .finally(() => {
          this.drawerLocal = false
          this.disableLoadingDialog()
        })
    },
    signOut() {
      aws.signOut()
        .then(() => {
          this.$api.player.clearSessionData()
          this.showMessage('You have been signed out successfully.')
        })
        .catch((err) => {
          this.showMessage(`An error has occurred: ${err.message}`)
        })
        .finally(() => {
          this.drawerLocal = false
          this.rtr.push({ name: routeNames.LANDING })
          this.disableLoadingDialog()
        })
    },
    joinActivity() {
      this.drawerLocal = false
      if (this.rt.name !== 'JoinActivity') {
        this.$router.push({ name: routeNames.JOIN_ACTIVITY })
      }
    }
  },
}
</script>

<style>
#nav-drawer {
  padding: 1rem;
}
</style>
