<template>
  <v-card :loading='loading' class='mb-6'>
    <v-card-title class='opm-view-card-title'>Player Select</v-card-title>
    <v-card-text class='opm-view-card-content'>
      <v-row class='mt-2' justify="center">
        <v-col align='center' v-for='(trace, index) in traces' :key='trace.log_id'>
          <v-btn v-bind:color="getIsButtonActive(index) ? 'var(--secondary)' : 'var(--secondary-lighten)'" @click.prevent='resetToPlayersFirstSnapshot(index)'>Player {{index+1}}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { traceColors } from '@/store/modules/opm'

export default {
  name: 'PlayerSelect',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions(['setSelectedLogIdIndex', 'setSelectedSnapshot']),
    getIsButtonActive(index) {
      return this.selectedLogIdIndex === index
    },
    resetToPlayersFirstSnapshot(index) {
      this.setSelectedLogIdIndex(index)
      this.setSelectedSnapshot({ logIdIndex: index, eventIndex: 0 })
    }
  },
  computed: {
    ...mapGetters(['traces', 'selectedLogIdIndex']),
    traceColorsLocal() {
      return traceColors || [{ node: 'grey' }]
    },
  }
}
</script>

<style scoped>
  .player-button-inactive {
    opacity: 0.5
  }
  .player-button-active {
    opacity: 1
  }
</style>
