<template>
  <v-card :loading='loading' class='mb-6'>
    <v-card-title class='opm-view-card-title'>Player Graph</v-card-title>
    <v-card-text class='opm-view-card-content'>
      <div id='player-graph-mount-node' ref='playerGraphMountNode'>
        <svg v-if='tracesExist' :width='width' :height='height' id='player-graph'></svg>
      </div>
      <v-row v-if='enablePlayback' align="center">
        <v-col>
          <v-btn
            :disabled='!parentSnapshotChildrenLength'
            @click.prevent='beginSnapshotAnimation'
            >Animate Preceding Events ({{parentSnapshotChildrenLength}})
          </v-btn>
        </v-col>
        <v-col align='center'>
          <v-text-field
            v-model='animationDelay'
            type='number'
            label='Delay'
            hint='Milliseconds between each event'></v-text-field>
        </v-col>
        <v-col>
          <div><span class='snapshot-datum'>Current Event Type:</span> {{this.selectedSnapshot.type}}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as d3 from 'd3'
import { range } from 'lodash'
import { graphColors } from '@/store/modules/opm'
import { mapGetters, mapActions } from 'vuex'

const GRAPH_OFFSET = 50
const NODE_RADIUS = 8

export default {
  name: 'PlayerGraph',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      traceIndex: 0,
      eventIndex: 0,
      enablePlayback: false,
      totalHeight: 0,
      totalWidth: 0,
      selectedNode: null,
      animationDelay: 200,
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
    }
  },
  methods: {
    ...mapActions(['setSelectedSnapshot']),
    executeSerial(funcs) {
      const concat = (list) => Array.prototype.concat.bind(list)
      const promiseConcat = (f) => (x) => f().then(concat(x))
      const promiseReduce = (acc, x) => acc.then(promiseConcat(x))
      return funcs.reduce(promiseReduce, Promise.resolve([]))
    },
    async beginSnapshotAnimation() {
      // const parentSnapshot = this.traces[this.traceIndex].events[this.eventIndex]
      const parentSnapshot = JSON.parse(JSON.stringify(this.parentSnapshot))
      const eventsToAnimate = [...this.parentSnapshot.children, parentSnapshot]
      const funcs = eventsToAnimate.map((child, childIndex) => async () => {
        await new Promise((resolve) => setTimeout(resolve, this.animationDelay))
        if (child.type === 'BOARD_SNAPSHOT') {
          await this.setSelectedSnapshot({ logIdIndex: this.traceIndex, eventIndex: this.eventIndex })
        } else {
          await this.setSelectedSnapshot({ logIdIndex: this.traceIndex, eventIndex: this.eventIndex, childIndex })
        }
      })
      this.executeSerial(funcs)
    },
    renderChart() {
      d3.selectAll('svg > *').remove()
      let isActive = false
      const svg = d3.select('#player-graph')
      // AXES
      const xAxis = d3.axisBottom()
        .scale(this.xScale)
        .tickValues(range(0, this.maxX + 1, 1))

      svg.append('g')
        .attr('transform', `translate(0, ${this.height - GRAPH_OFFSET})`)
        .call(xAxis)

      const yAxis = d3.axisLeft()
        .scale(this.yScale)
        .tickValues(range(0, this.maxY, 20))
        .tickSizeOuter(0)

      svg.append('g')
        .attr('transform', `translate(${GRAPH_OFFSET}, 0)`)
        .call(yAxis)

      d3.selectAll('.node').attr('pointer-events', 'none')

      let selectedTrace = null
      let selectedTraceIndex = -1
      // find our selected/active trace
      this.formattedTraces.forEach((trace, traceIndex) => {
        isActive = traceIndex === this.selectedLogIdIndex
        if (isActive) {
          selectedTrace = trace
          selectedTraceIndex = traceIndex
        }
      })
      // create a new array by removing the selected trace, then add it back to the end
      const tracesToDraw = this.formattedTraces.slice()
      tracesToDraw.splice(selectedTraceIndex, 1)
      tracesToDraw.push(selectedTrace)
      // draw traces from our new array
      tracesToDraw.forEach((trace, traceIndex) => {
        isActive = traceIndex === this.selectedLogIdIndex
        const color = (isActive) ? graphColors.selected : graphColors.unselected
        svg.append('path')
          .datum(trace.events)
          .attr('fill', 'none')
          .attr('stroke', color.edge)
          .attr('stroke-width', 2)
          .attr('class', 'line')
          .attr('d', this.lineGenerator)

        svg.append('g')
          .selectAll('node')
          .data(trace.events)
          .enter()
          .append('circle')
          .attr('class', 'node')
          .attr('cx', (d, i) => this.xScale(i))
          .attr('cy', (d) => this.yScale(d.yAdj))
          .attr('r', NODE_RADIUS)
          .style('fill', (d) => {
            const result = d.submission_result.toUpperCase()
            if (result.includes('SUCCESS')) {
              return color.node.success
            }
            if (result.includes('FAILURE')) {
              return color.node.failure
            }
            return color.node.start
          })
          .attr('stroke', 'none')
          .attr('stroke-width', 0)
          .attr('pointer-events', isActive ? 'auto' : 'none')
          .on('mouseover', (d, i, nodes) => {
            nodes.forEach((n) => d3.select(n).transition().duration(100).attr('stroke', 'none'))
            const node = d3.select(nodes[i])
            this.setSelectedSnapshot({ logIdIndex: traceIndex, eventIndex: i })
            this.traceIndex = traceIndex
            this.eventIndex = i
            this.selectedNode = d
            node.transition()
              .duration(100)
              .attr('stroke', color.edge)
              .attr('stroke-width', 2)
          })
      })

      // LABELS
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', 0 - (this.height / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('Efficiency')

      svg.append('text')
        .attr('y', this.height - this.margin.top - this.margin.bottom)
        .attr('x', this.width / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('Test/Submits')
    },
    getMaxXDimFromTraces() {
      return Math.max(...this.formattedTraces.map((ft) => ft.events.length))
    },
    getMaxYDimFromTraces() {
      if (!this.formattedTraces.length) return 0
      const eventsFlat = [...this.formattedTraces.map((trace) => trace.events)].flat()
      return Math.max(...eventsFlat.map((event) => event.y))
    }
  },
  computed: {
    ...mapGetters(['traces', 'selectedSnapshot', 'selectedLogIdIndex']),
    parentSnapshotChildrenLength() {
      return this.parentSnapshot.children.length
    },
    tracesExist() {
      return this.traces.length
    },
    parentSnapshot() {
      if (!this.tracesExist) return { children: [] }
      return this.traces[this.traceIndex].events[this.eventIndex]
    },
    formattedTraces() {
      return this.traces.map((trace, i) => {
        const add = i * 2
        return {
          log_id: trace.log_id,
          events: trace.events.map((event) => {
            const y = typeof event.ticks === 'number' ? event.ticks : 0
            return { ...event, y, yAdj: y + add }
          })
        }
      })
    },
    width() {
      return Math.max(this.totalWidth - this.margin.left - this.margin.right, 0)
    },
    height() {
      return Math.max(this.totalHeight - this.margin.top - this.margin.bottom, 0)
    },
    selectedNodeText() {
      if (!this.selectedNode) return ''
      return this.selectedNode.type
    },
    lineGenerator() {
      return d3.line()
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d.yAdj))
    },
    maxX() {
      return this.getMaxXDimFromTraces()
    },
    maxY() {
      return this.getMaxYDimFromTraces()
    },
    xScale() {
      return d3.scaleLinear()
        .domain([0, this.getMaxXDimFromTraces()])
        .range([GRAPH_OFFSET, this.width - this.margin.right])
    },
    yScale() {
      return d3.scaleLinear()
      // .domain specific to the data
      // .range is specific to the graph
        .domain([this.getMaxYDimFromTraces(), 0])
        .range([this.margin.top + this.margin.bottom, this.height - GRAPH_OFFSET])
    }
  },
  updated() {
    this.renderChart()
  },
  watch: {
    selectedLogIdIndex: {
      handler() {
        this.renderChart()
      },
      deep: true,
      immediate: true
    },
  },
  beforeUpdate() {
    d3.select('#player-graph')
      .selectAll('*')
      .remove()
  },
  mounted() {
    this.totalWidth = this.$refs.playerGraphMountNode.clientWidth
    this.totalHeight = this.$refs.playerGraphMountNode.clientHeight
    this.renderChart()
  }
}
</script>

<style>
  .node {
    cursor: pointer;
  }
  #player-graph-mount-node {
    min-height: 600px;
    width: 100%;
  }
</style>
