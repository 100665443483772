import { dispatch } from 'd3'
import ImageElement from './ImageElement'
import { elementType } from '../vars'

export default class Hint extends ImageElement {
  get suffix() {
    return ''
  }

  get type() {
    return elementType.HINT
  }

  get name() {
    return 'hint'
  }

  get alt() {
    return `${this.capitalize(this.type)} ${this.suffix}`
  }

  get isActionable() {
    return true
  }

  get isClickable() {
    return true
  }

  get isToggleable() {
    return true
  }

  get isBoardImageElement() {
    return true
  }

  get isLinkElement() {
    return false
  }

  get isBoardElement() {
    return true
  }

  get userPlaced() {
    return false
  }

  get image() {
    if (!this.spec) return this.primaryImage
    return this.spec === 'active' ? this.primaryImage : this.secondaryImage
  }

  get coords() {
    return [
      this.data.targetEl.coordinates[0] / 2 + this.data.sourceEl.coordinates[0] / 2,
      this.data.targetEl.coordinates[1] / 2 + this.data.sourceEl.coordinates[1] / 2]
  }

  get cell() {
    return [
      Math.floor(this.data.targetEl.cell[0] / 2 + this.data.sourceEl.cell[0] / 2),
      Math.floor(this.data.targetEl.cell[1] / 2 + this.data.sourceEl.cell[1] / 2)
    ]
  }

  onMouseDown() {
    this.onToggle()
  }

  async onToggle() {
    const res = await this.data.onMouseDownAction()
    if (!res) return
    if (this.spec === 'active') {
      this.spec = 'inactive'
    } else {
      this.spec = 'active'
    }
  }

  draw(ctx) {
    // Save current context settings
    ctx.save()
    const y = this.data.coords[0]
    const x = this.data.coords[1]
    ctx.drawImage(this.image, x, y, this.width, this.height)
    // Restore previous context settings
    ctx.restore()
  }

  getScreenCoordinates() {
    const [y, x] = this.coords
    let newY = y
    let newX = x
    if (this.isBoardElement) {
      newX *= this.transform.k
      newY *= this.transform.k
      newX += this.transform.x
      newY += this.transform.y
    }
    return [newY + this.radius, newX + this.radius]
  }

  detectCollisionByPoint(y, x) {
    const [y2, x2] = this.getScreenCoordinates()
    return Math.abs(y2 - y) < this.radius && Math.abs(x2 - x) < this.radius
  }
}
