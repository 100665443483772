import Thread from '@/elements/Thread'
import Pickup from '@/elements/Pickup'
import Delivery from '@/elements/Delivery'
import Semaphore from '@/elements/Semaphore'
import Trash from '@/elements/Trash'
import Conditional from '@/elements/Conditional'
import Switch from '@/elements/Switch'
import Package from '@/elements/Package'
import Exchange from '@/elements/Exchange'
import Signal from '@/elements/Signal'
import Track from '@/elements/Track'
import Link from '@/elements/Link'
import Mouse from '@/elements/Mouse'
import LinksIndicator from '@/elements/LinksIndicator'
import Hint from '@/elements/Hint'
import { elementType } from '../../vars'

/**
 * Creates a new image asset and loads it before returning
 * @param name name of image element to be created
 * @param width width of image element to be created
 * @param height height of image element to be created
 * @return img Image created
 */
async function createImageByName(name, width = 100, height = 100) {
  const img = new Image(width, height)
  img.name = name
  img.src = require(`@/assets/icons/${name}.png`)
  img.className = 'no-display'
  await img.decode()
  return img
}

/**
     * Loads images by name and adds them to top-level images variable.
     * Used primarily for packages, which are not in the initial data level, but rather
     * added programatically by the user
     */
async function createDynamicImageElements() {
  const names = [
    'packageLimCondition',
    'packageUnlimCondition',
    'packageUnlimUncondition',
    'semaphoreActive',
    'semaphoreInactive',
    'signalActive',
    'signalInactive',
    'trashActive',
    'trashInactive',
    'helpActive',
    'linksIndicatorActive',
    'linksIndicatorInactive',
    'hint',
    'hintActiveAccurate',
    'hintInactiveAccurate',
    'hintActiveInaccurate',
    'hintInactiveInaccurate'
  ]
  return Promise.all(names.map(async (name) => createImageByName(name)))
}

function createPathElementFromData(data) {
  if (data.isBoardElement) {
    return new Track(data)
  }
  if (data.type === elementType.LINK) {
    return new Link(data)
  }
  if (data.type === elementType.MOUSE) {
    return new Mouse(data)
  }
  console.error('Path type not found')
  return null
}

async function createImageElementFromData(data) {
  const { type } = data
  if (!type) console.error('Type must be set on data.')
  let el
  if (type === elementType.THREAD) {
    el = new Thread(data)
  }
  if (type === elementType.PICKUP) {
    el = new Pickup(data)
  }
  if (type === elementType.DELIVERY) {
    el = new Delivery(data)
  }
  if (type === elementType.SEMAPHORE) {
    el = new Semaphore(data)
  }
  if (type === elementType.CONDITIONAL) {
    el = new Conditional(data)
  }
  if (type === elementType.SWITCH) {
    el = new Switch(data)
  }
  if (type === elementType.EXCHANGE) {
    el = new Exchange(data)
  }
  if (type === elementType.SIGNAL) {
    el = new Signal(data)
  }
  if (type === elementType.TRASH) {
    el = new Trash(data)
  }
  if (type === elementType.PACKAGE) {
    el = new Package(data)
  }
  if (type === elementType.LINKS_INDICATOR) {
    el = new LinksIndicator(data)
  }
  if (type === elementType.INTERSECTION) {
    console.error('Intersections should not exist. Giving you a signal instead')
    el = new Signal(data)
  }
  if (type === elementType.HINT) {
    el = new Hint(data)
  }
  if (!el) {
    console.error(`This type of element not recognized: ${type}`)
  }
  return el
}

export default {
  createImageByName,
  createDynamicImageElements,
  createPathElementFromData,
  createImageElementFromData
}
