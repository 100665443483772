<template>
  <div class="parent">
    <div class="header"></div>
    <div v-if='!this.selected' class="toggle-parent">
      <div class="toggle-button-parent">
        <v-btn @click.prevent='toggle' class="toggle-button">Select Level</v-btn>
      </div>
      <div v-if="this.active" class="toggle-items">
        <v-btn @click.prevent='selectLevel(3)'>Level 3</v-btn>
        <v-btn @click.prevent='selectLevel(4)'>Level 4</v-btn>
        <v-btn @click.prevent='selectLevel(7)'>Level 7</v-btn>
        <v-btn @click.prevent='selectLevel(8)'>Level 8</v-btn>
        <v-btn @click.prevent='selectLevel(13)'>Level 13</v-btn>
        <v-btn @click.prevent='selectLevel(15)'>Level 15</v-btn>
      </div>
    </div>
    <div v-if="this.tracesLoaded" class="content-parent">
      <div class="player-view" ref="playerView">
        <img class="player-board-image" :src='this.getScreenshot(this.selectedLogIndexCurrentPlayer,this.selectedEventIndexCurrentPlayer)'>
        <!-- LevelView class='opm-level-view' :mapConfig="mapConfig"/ -->
      </div>
      <div class="selected-view">
        <div class="selected-header">
          <div class="selected-header-icon">
            <img :src=getIconImage() style="width:50px;height:50px" />
          </div>
          <div class="selected-header-title">
            {{getSelectedTitle}}
          </div>
        </div>
        <div class="selected-subtitle">
          {{getSelectedSubtitle}}
        </div>
        <div class="selected-board">
          <div class="selected-no-player" v-if='!playerSelected'>
            <span class="text-span">
              {{getNoPlayerText}}
            </span>
          </div>
          <div class="selected-name" v-if='playerSelected'>
            {{getSelectedPlayerName}}
          </div>
          <img class="selected-board-image" v-if='playerSelected' :src='this.getScreenshot(this.selectedLogIndexOtherPlayer,this.selectedEventIndexOtherPlayer)'>
        </div>
        <div class="selected-body">
          {{getDirectionText}}
          <br />
          {{getSuggestionText}}
        </div>
      </div>
      <div class="similarity-graph" id="similarity-graph" ref="similarityGraph">
        <!-- div class="similarity-help" v-if='showHelp'>
          <div class="help-text">
            {{getHelpText}}
          </div>
        </div -->
        <!-- div class="similarity-graph-title">
          {{getSimilarityTitle}}
        </div -->
        <svg class="similarity-graph-svg" v-if='tracesExist' id='similarity-graph-svg'></svg>
      </div>
      <div class="trace-graph" id="trace-graph" ref="traceGraph">
        <svg class="trace-key-svg" id="trace-key-svg"></svg>
        <svg class="trace-graph-svg" v-if='tracesExist' id='trace-graph-svg'></svg>
      </div>
    </div>
  </div>
</template>

<script>
  // import * as d3 from 'd3'
  // import { range } from 'lodash'
  import { graphColors } from '@/store/modules/opm'
  import { range } from 'lodash'
  import * as d3 from 'd3'
  import { mapGetters, mapActions } from 'vuex'
  import LevelView from '@/components/level/LevelView.vue'

  const TRACE_TOP_OFFSET = -20
  const TRACE_LEFT_OFFSET = 75
  const TRACE_RIGHT_OFFSET = 175
  const SIMILARITY_TOP_OFFSET = 15
  const SIMILARITY_X_OFFSET = 3
  const SIMILARITY_Y_OFFSET = 10
  const NODE_RADIUS = 8

  export default {
    name: 'ViewPortOPM',
    components: {
      // LevelView,
    },
    data() {
      return {
        mapConfig: {
          isDisabled: true,
          iconSize: 100,
          width: 1007,
          height: 535
        },
        testData: 'test123',
        selectedLogIndexCurrentPlayer: 0,
        selectedEventIdCurrentPlayer: 0,
        selectedEventIndexCurrentPlayer: 0,
        selectedLogIndexOtherPlayer: 0,
        selectedEventIdOtherPlayer: 0,
        selectedEventIndexOtherPlayer: 0,
        loadingTraces: false,
        totalPlayerWidth: 1000,
        totalPlayerHeight: 500,
        totalTraceHeight: 400,
        totalTraceWidth: 500,
        totalSimilarityHeight: 400,
        totalSimilarityWidth: 500,
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        displayAllEventsForThesePlayers: [],
        dataPoints: [],
        showHelp: false,
        active: false,
        selected: false,
        tracesLoaded: false
      }
    },
    watch: {
      tracesLoaded(value) {
        if (value === true) {
          this.$nextTick(() => {
            this.setupOPMView()
          })
        }
      }
    },
    methods: {
      ...mapActions([
        'getSetTraces',
        'addToShowTrace',
        'addToMarkAsRecommendation',
        'emptyMarkedRecommendations',
        'emptyTracesToShow',
        'setRecommendedPlayersForCurrentEvent',
        'showMessage'
      ]),
      async init() {
        try {
          await this.getSetTraces()
        } catch (e) {
          this.showMessage(e)
        } finally {
          console.log('done loading')
        }
      },
      async toggle() {
        console.log('toggle time')
        this.active = !this.active
      },
      async selectLevel(index) {
        console.log(`selected level: ${index}`)
        this.selected = true
        await this.getTraces(index)
      },
      async getTraces(index) {
        this.loadingTraces = true
        try {
          await this.getSetTraces(index)
        } catch (e) {
          this.showMessage(e)
        } finally {
          console.log('done')
          this.selectedLogIndexCurrentPlayer = 0
          this.selectedEventIdCurrentPlayer = 0
          this.selectedEventIndexCurrentPlayer = 0
          this.selectedLogIndexOtherPlayer = 0
          this.selectedEventIdOtherPlayer = 0
          this.selectedEventIndexOtherPlayer = 0
          this.emptyTracesToShow()
          this.loadingTraces = false
          this.tracesLoaded = true
        }
      },
      async setupOPMView() {
        console.log('setup opm view')
        for (let i = 0; i < this.playerTraces.length; i++) {
          if (this.playerTraces[i].is_current_user) {
            this.selectedLogIndexCurrentPlayer = i
          }
        }
        this.mapConfig.width = this.$refs.playerView.clientWidth
        this.mapConfig.height = this.$refs.playerView.clientHeight
        this.totalTraceWidth = this.$refs.traceGraph.clientWidth
        this.totalTraceHeight = this.$refs.traceGraph.clientHeight
        this.totalSimilarityWidth = this.$refs.similarityGraph.clientWidth
        this.totalSimilarityHeight = this.$refs.similarityGraph.clientHeight
        this.renderSimilarityGraph()
        this.renderTraceKey()
        this.renderTraces()
      },
      getIconImage() {
        // const img = require(`../../assets/icons/${this.communityContent.icon}.png`)
        const img = require('../../assets/icons/hint.png')
        return img
      },
      getScreenshot(logIndex, eventId) {
        return this.currentScreenshotURL(logIndex, eventId)
      },
      colorSelect(move_classification, eventID) {
        if (this.markAsRecommendation.includes(eventID)) {
          return 'orange'
        }
        return this.nodeColors[move_classification]
      },
      renderSimilarityGraph() {
        const svg = d3.select('#similarity-graph-svg')
        svg.selectAll('*').remove()

        // render graph lines
        svg.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 1)
          .attr('x1', this.similarityWidth / 2)
          .attr('y1', SIMILARITY_TOP_OFFSET)
          .attr('x2', this.similarityWidth / 2)
          .attr('y2', this.similarityHeight + SIMILARITY_TOP_OFFSET * 2)

        svg.append('line')
          .style('stroke', 'black')
          .style('stroke-width', 1)
          .attr('x1', 0)
          .attr('y1', this.similarityHeight / 2 + SIMILARITY_TOP_OFFSET)
          .attr('x2', this.similarityWidth)
          .attr('y2', this.similarityHeight / 2 + SIMILARITY_TOP_OFFSET)

        svg.selectAll('g').remove()
        const dataPoints = svg.selectAll('g')
          .data(this.traceStats)

        const point = dataPoints.enter()
          .append('g')
          .attr('transform', (d) => `translate(${(this.similarityXScale(d.ticks))}, ${(this.similarityYScale(d.idle))})`)

        point.append('circle')
          .attr('r', 5)
          .style('stroke', 'black')
          .style('fill', (d) => {
            if (d.traceIndex === this.selectedLogIndexCurrentPlayer || this.tracesToShow.includes(d.traceIndex)) {
              return 'orange'
            }
            return 'none'
          })

        point.append('text')
          .attr('dx', -9)
          .attr('dy', -10)
          .text((d) => {
            if (d.traceIndex === 0) {
              return 'YOU'
            }
            return (`P${d.traceIndex}`)
          })

        svg.append('rect')
          .attr('class', 'titleBackground')
          .attr('x', 5)
          .attr('y', 5)
          .attr('width', 500)
          .attr('height', 15)
          .style('fill', 'white')

        svg.append('text')
          .attr('class', 'similarityTitle')
          .attr('x', 5)
          .attr('y', 15)
          .style('font-family', 'Arial')
          .style('font-weight', '300')
          .style('font-size', '13')
          .text(this.getSimilarityTitle)

        svg.append('text')
          .attr('class', 'similarityTitle')
          .attr('x', this.similarityWidth - SIMILARITY_X_OFFSET * 17)
          .attr('y', this.similarityHeight / 2 + SIMILARITY_Y_OFFSET)
          .style('font-family', 'Arial')
          .style('font-weight', '200')
          .style('font-size', '10')
          .text(this.getSimilarityLabels[0])

        svg.append('text')
          .attr('class', 'similarityTitle')
          .attr('x', SIMILARITY_X_OFFSET)
          .attr('y', this.similarityHeight / 2 + SIMILARITY_Y_OFFSET)
          .style('font-family', 'Arial')
          .style('font-weight', '200')
          .style('font-size', '10')
          .text(this.getSimilarityLabels[1])

        svg.append('text')
          .attr('class', 'similarityTitle')
          .attr('x', this.similarityWidth / 2 + SIMILARITY_X_OFFSET)
          .attr('y', SIMILARITY_Y_OFFSET * 3)
          .style('font-family', 'Arial')
          .style('font-weight', '200')
          .style('font-size', '10')
          .text(this.getSimilarityLabels[2])

        svg.append('text')
          .attr('class', 'similarityTitle')
          .attr('x', this.similarityWidth / 2 + SIMILARITY_X_OFFSET)
          .attr('y', this.similarityHeight + SIMILARITY_Y_OFFSET * 2)
          .style('font-family', 'Arial')
          .style('font-weight', '200')
          .style('font-size', '10')
          .text(this.getSimilarityLabels[3])

        svg.append('circle')
          .attr('class', 'helpButton')
          .attr('cx', this.similarityWidth - 20)
          .attr('cy', 20)
          .attr('r', 15)
          .style('fill', '#FDF9BD')
          .style('stroke', 'black')
          .on('click', () => {
            console.log('clicking')
            this.toggleHelp()
          })

        svg.append('text')
          .attr('class', 'helpButtonText')
          .attr('x', this.similarityWidth - 25)
          .attr('y', 25)
          .attr('pointer-events', 'none')
          .style('font-family', 'Arial')
          .style('font-weight', '600')
          .text('?')

        if (this.showHelp) {
          svg.append('rect')
            .attr('class', 'helpBackground')
            .attr('x', this.similarityWidth - (this.similarityWidth * 0.66) - 5)
            .attr('y', 40)
            .attr('width', this.similarityWidth * 0.66)
            .attr('height', this.similarityHeight * 0.5)
            .style('fill', '#FDF9BD')
            .style('stroke', 'black')

          const helpSize = 13

          for (let i = 0; i < this.getHelpText.length; i++) {
            svg.append('text')
              .attr('class', 'helpText')
              .attr('x', this.similarityWidth - (this.similarityWidth * 0.66))
              .attr('y', 55 + helpSize * i)
              .attr('dy', 0)
              .text(this.getHelpText[i])
              .style('font-family', 'Arial')
              .style('font-weight', '300')
              .style('font-size', `${helpSize}`)
          }
        }
        console.log(svg)
      },
      renderTraceKey() {
        const svg = d3.select('#trace-key-svg')
        svg.selectAll('*').remove()

        const data = [
          { color: this.nodeColors.ignore, text: 'Intermediate Move' },
          { color: this.nodeColors.good, text: 'Accurate Move' },
          { color: this.nodeColors.bad, text: 'Inaccurate Move' },
          { color: 'orange', text: 'Recommended Move' },
        ]

        for (let i = 0; i < data.length; i++) {
          const index = i
          svg.append('circle')
            .style('fill', data[index].color)
            .attr('class', 'keynode')
            .attr('cx', index * (this.traceWidth / 4) + (this.traceWidth / 16) - 16)
            .attr('cy', 15)
            .attr('r', 8)

          svg.append('text')
            .attr('class', 'keylable')
            .style('font-family', 'Arial')
            .style('font-weight', '700')
            .text(data[index].text)
            .attr('x', index * (this.traceWidth / 4) + (this.traceWidth / 16))
            .attr('y', 20)
        }

        console.log(svg)
      },
      renderTraces() {
        console.log('Render Traces')
        console.log(this.nodeColors)
        this.lineData = []

        const svg = d3.select('#trace-graph-svg')
        svg.selectAll('*').remove()

        this.tracesToShow.forEach((logIndex, traces_to_show_index) => {
          let playerEvents = this.playerTraces[logIndex].events
          if (!this.displayAllEventsForThesePlayers.includes(logIndex)) {
            const indexOfSuggestedEvent = this.playerTraces[logIndex].events.findIndex((event) => {
              if (this.colorSelect(event.move_classification, event.id) === 'orange') {
                return true
              }
              return false
            })

            if (indexOfSuggestedEvent !== -1) {
              playerEvents = this.playerTraces[logIndex].events.slice(indexOfSuggestedEvent - 2, indexOfSuggestedEvent + 3)
            }
          }

          svg.append('g')
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
            .selectAll('.labelbackground')
            .data(this.playerTraces[logIndex].events)
            .enter()
            .append('rect')
            .attr('class', 'labelbackground')
            .attr('x', 0 - 5)
            .attr('y', (d) => this.yScale(traces_to_show_index) - 20)
            .attr('width', 50)
            .attr('height', 20)
            .style('fill', 'lightgrey')

          svg.append('g')
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
            .selectAll('.textlabel')
            .data(this.playerTraces[logIndex].events)
            .enter()
            .append('text')
            .attr('class', 'textlabel')
            .style('font-family', 'Arial')
            .style('font-weight', '700')
            .text((d) => {
              if (logIndex === 0) {
                return 'YOU'
              }
              return `P${logIndex}`
            })
            .attr('x', 0)
            .attr('y', (d) => this.yScale(traces_to_show_index) - 5)

          svg.append('g')
            .attr('class', `player${logIndex}-g-selection`)
            .selectAll('node-selection')
            .data(playerEvents)
            .enter()
            .append('rect')
            .attr('x', (d, i) => this.xScale(i) - 10)
            .attr('y', (d) => this.yScale(traces_to_show_index) - 10)
            .attr('width', 20)
            .attr('height', 20)
            .style('fill', 'none')
            .style('stroke', (d, i) => {
              if (logIndex === 0 && this.selectedEventIndexCurrentPlayer === i) {
                return 'black'
              }
              if (logIndex !== 0 && logIndex === this.selectedLogIndexOtherPlayer && this.playerTraces[logIndex].events[this.selectedEventIndexOtherPlayer].id === d.id) {
                return 'black'
              }
              return 'none'
            })

          svg.append('g')
            .attr('class', `player${logIndex}-g`)
            .selectAll('node')
            .data(playerEvents)
            .enter()
            .append('circle')
            .style('fill', (d, i) => this.colorSelect(d.move_classification, d.id))
            .attr('class', 'node')
            .attr('cx', (d, i) => this.xScale(i))
            .attr('cy', (d) => this.yScale(traces_to_show_index))
            .attr('r', 8)
            .on('click', (d, i) => {
              // TODO : if I click on the current player play trace
              if (this.selectedLogIndexCurrentPlayer === logIndex) {
                  // setting the Clicked node of current player
                  this.selectedEventIdCurrentPlayer = i
                  this.selectedEventIndexCurrentPlayer = i
                  // TODO : Clear Chart
                  // Clearing recommended events
                  this.emptyMarkedRecommendations()
                  // Clearing all traces
                  this.emptyTracesToShow()
                  // Iterate through recommendations for the current event
                  // for (const [playerID, eventID] of Object.entries(d.recommended_players)) {
                  // // TODO: get log index of players recommened and add them to traces to show
                  // this.addToShowTrace(this.indexForLogId(playerID))
                  // // TODO: add those to show yellow/something to let user pay attention
                  // this.addToMarkAsRecommendation(eventID)
                  // }
                // TODO: Render canvas accordingly! (make sure you check also add the color part in the graph building part)
                if (d.recommended_suggestion !== '') {
                  this.selectedPlayerText = d.recommended_suggestion
                } else {
                  this.selectedPlayerText = 'Click a node on your play trace to update the viewport. Click the (+) button to add another player to your play trace to see how others have handled a particular accurate or inaccurate move.'
                }
                this.recommendedPlayerText = ''
                this.selectedLogIndexOtherPlayer = 0
                this.selectedEventIndexOtherPlayer = 0
                this.selectedEventIdOtherPlayer = 0
              } else { // clicking on the node from another player
                this.selectedLogIndexOtherPlayer = logIndex
                this.selectedEventIndexOtherPlayer = this.playerTraces[logIndex].events.findIndex((e) => e.id === d.id)
                this.selectedEventIdOtherPlayer = d.id
                this.selectedPlayerText = this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].for_me_to_think
                // from the traces, get the current player, get the locked event in the current player
                // .events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players
                for (const [playerID, correction] of Object.entries(this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players)) {
                  if (this.indexForLogId(playerID) === logIndex && this.markAsRecommendation.includes(this.playerTraces[this.selectedLogIndexOtherPlayer].events[this.selectedEventIndexOtherPlayer].id)) {
                    this.recommendedPlayerText = correction
                  }
                }
              }
              this.renderTraces()
              this.renderSimilarityGraph()
              this.renderTraceKey()
            })

          const newData = svg.select(`.player${logIndex}-g`).selectAll('.node').data()
          svg.select(`.player${logIndex}-g`).selectAll('.node').each((d, i, n) => {
            newData[i].cx = d3.select(n[i]).attr('cx')
            newData[i].cy = d3.select(n[i]).attr('cy')
          })

          svg.selectAll(`.player${logIndex}-g`)
            .append('text')
            .attr('class', 'show-full')
            .text('Show Full')
            .attr('x', Number(newData[newData.length - 1].cx) + 40)
            .attr('y', Number(newData[newData.length - 1].cy) + 6)
            .style('fill', () => {
              if (this.displayAllEventsForThesePlayers.indexOf(logIndex) >= 0) {
                return 'green'
              }
              return 'gray'
            })
            .style('cursor', 'pointer')
            .on('click', () => {
              const index = this.displayAllEventsForThesePlayers.indexOf(logIndex)
              if (index < 0) {
                this.displayAllEventsForThesePlayers.push(logIndex)
              }
              this.renderTraces()
              this.renderSimilarityGraph()
              this.renderTraceKey()
            })

          svg.selectAll(`.player${logIndex}-g`)
            .append('text')
            .text('|')
            .attr('x', Number(newData[newData.length - 1].cx) + 115)
            .attr('y', Number(newData[newData.length - 1].cy) + 6)
            .style('font-size', 20)
            .style('fill', 'gray')
            .style('cursor', 'pointer')

          svg.selectAll(`.player${logIndex}-g`)
            .append('text')
            .attr('class', 'minimize')
            .text('Minimize')
            .attr('x', Number(newData[newData.length - 1].cx) + 125)
            .attr('y', Number(newData[newData.length - 1].cy) + 6)
            .style('fill', 'gray')
            .style('cursor', 'pointer')
            .on('click', () => {
              const index = this.displayAllEventsForThesePlayers.indexOf(logIndex)
              if (index >= 0) {
                // Remove the player from the list to render all events
                this.displayAllEventsForThesePlayers.splice(index, 1)
              }
              this.renderTraces()
              this.renderSimilarityGraph()
              this.renderTraceKey()
              // svg.selectAll(`.player${logIndex}-g`).selectAll('text').style('fill', 'gray')
            })

          const filteredNewData = newData.filter((node) => {
            if (logIndex === 0) {
              if (Object.keys(node.recommended_players).length !== 0) return 1
              if (node.recommended_suggestion.length) return 1
            }
            if (this.markAsRecommendation.includes(node.id)) {
              return 1
            }
            return 0
          })

          if (traces_to_show_index > 0 && traces_to_show_index === this.getRecommendedPlayersForCurrentEvent.length) {
            filteredNewData.pop()
          }
          svg.append('g')
            .selectAll('node')
            .data(filteredNewData)
            .enter()
            .append('path')
            .style('fill', (d) => this.colorSelect(d.move_classification, d.id))
            .attr('class', 'node-expand')
            .attr('d', 'M 17.18,11.56 C 17.18,11.56 11.55,11.56 11.55,11.56 11.55,11.56 11.55,17.18 11.55,17.18 11.55,17.18 6.44,17.18 6.44,17.18 6.44,17.18 6.44,11.56 6.44,11.56 6.44,11.56 0.82,11.56 0.82,11.56 0.82,11.56 0.82,6.44 0.82,6.44 0.82,6.44 6.44,6.44 6.44,6.44 6.44,6.44 6.44,0.82 6.44,0.82 6.44,0.82 11.55,0.82 11.55,0.82 11.55,0.82 11.55,6.44 11.55,6.44 11.55,6.44 17.18,6.44 17.18,6.44 17.18,6.44 17.18,11.56 17.18,11.56 Z')
            .attr('transform', (d) => `translate(${Number(d.cx) - 9}, ${Number(d.cy) + 10})`)
            .attr('stroke', '#000000')
            .attr('opacity', (d, i) => {
              if (logIndex === 0) {
                if (Object.keys(d.recommended_players).length !== 0) return 1
                if (d.recommended_suggestion.length) return 1
              }
              // renderTraces() is called after every click, so markAsrecommendation will be updated and a "+" will be visible
              if (this.markAsRecommendation.includes(d.id)) {
                return 1
              }
              return 0
            })
            .on('click', (d, i) => {
              // '+' button click functionality
              if (this.selectedLogIndexCurrentPlayer === logIndex) {
                this.clickedNode = d
                // setting the Clicked node of current player
                this.selectedEventIdCurrentPlayer = this.indexForEventId(this.selectedLogIndexCurrentPlayer, d.id)
                this.selectedEventIndexCurrentPlayer = this.indexForEventId(this.selectedLogIndexCurrentPlayer, d.id)
                // TODO : Clear Chart
                // Clearing recommended events
                this.emptyMarkedRecommendations()
                // Clearing all traces
                this.emptyTracesToShow()
                this.displayAllEventsForThesePlayers = []
                this.indexTrack = 0
                // Iterate through recommendations for the current event
                const recommendedPlayers = Object.entries(d.recommended_players).map(([playerID, eventID]) => ({ playerID, eventID }))
                this.setRecommendedPlayersForCurrentEvent(recommendedPlayers)
                const { playerID, eventID } = recommendedPlayers[this.indexTrack]
                this.indexTrack += 1
                if (this.indexTrack === recommendedPlayers.length) this.showMessage('All Recommended Players are shown')
                this.addToShowTrace(this.indexForLogId(playerID))
                this.addToMarkAsRecommendation(eventID)
                this.selectedLogIndexOtherPlayer = this.indexForLogId(playerID)
                this.selectedEventIndexOtherPlayer = this.playerTraces[this.selectedLogIndexOtherPlayer].events.findIndex((e) => e.id === eventID)
                this.selectedEventIdOtherPlayer = eventID
                this.selectedPlayerText = this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].for_me_to_think
                for (const [playerID, correction] of Object.entries(this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players)) {
                  if (this.indexForLogId(playerID) === logIndex && this.markAsRecommendation.includes(this.playerTraces[this.selectedLogIndexOtherPlayer].events[this.selectedEventIndexOtherPlayer].id)) {
                    this.recommendedPlayerText = correction
                  }
                }
              } else { // If clicking on the + sign which is not the current player
                const recommendedPlayers = this.getRecommendedPlayersForCurrentEvent
                if (this.indexTrack < recommendedPlayers.length && this.tracesToShow[this.tracesToShow.length - 1] === logIndex) {
                  const { playerID, eventID } = recommendedPlayers[this.indexTrack]
                  this.addToShowTrace(this.indexForLogId(playerID))
                  this.indexTrack += 1
                  if (this.indexTrack === recommendedPlayers.length) this.showMessage('All Recommended Players are shown')
                  this.addToMarkAsRecommendation(eventID)
                  this.selectedLogIndexOtherPlayer = this.indexForLogId(playerID)
                  this.selectedEventIndexOtherPlayer = this.playerTraces[this.selectedLogIndexOtherPlayer].events.findIndex((e) => e.id === eventID)
                  this.selectedEventIdOtherPlayer = eventID
                  this.selectedPlayerText = this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].for_me_to_think
                  for (const [playerID, correction] of Object.entries(this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players)) {
                    if (this.indexForLogId(playerID) === logIndex && this.markAsRecommendation.includes(this.playerTraces[this.selectedLogIndexOtherPlayer].events[this.selectedEventIndexOtherPlayer].id)) {
                      this.recommendedPlayerText = correction
                    }
                  }
                }
              }
              this.renderTraces()
              this.renderSimilarityGraph()
              this.renderTraceKey()
            })
        })
        const recommendedPlayers = this.getRecommendedPlayersForCurrentEvent
        if (recommendedPlayers.length) {
          this.lineData.push(this.clickedNode)
          this.tracesToShow.forEach((player, index) => {
            if (player !== 0) {
              const { eventID } = recommendedPlayers[index - 1] // This starts at a diff index since this is a structure that doesn't include the original curr player event node
              this.lineData.push(this.playerTraces[player].events.filter((event) => event.id === eventID)[0])
            }
          })
        }

        if (this.lineData) {
          svg.append('g')
            .selectAll('node')
            .data(this.lineData)
            .enter()
            .append('line')
            .attr('class', 'node-line')
            .attr('x1', (d, i) => d.cx)
            .attr('x2', (d, i) => {
              if (i < this.lineData.length - 1) return this.lineData[i + 1].cx
              // Create a non existing line for the last recommended node (basically drawing to itself)
              return d.cx
            })
            .attr('y1', (d, i) => d.cy)
            .attr('y2', (d, i) => {
              if (i < this.lineData.length - 1) return this.lineData[i + 1].cy
              // Create a non existing line for the last recommended node (basically drawing to itself)
              return d.cy
            })
            .attr('stroke', '#000000')
        }
        console.log(svg)
      },
      getMaxXDimFromTraces() {
        return Math.max(...this.formattedTraces.map((ft) => ft.events.length))
      },
      getMaxYDimFromTraces() {
        return this.tracesToShow.length
      },
      getSimilarityMinX() {
        return Math.min(...this.traceStats.map((ts) => ts.ticks))
      },
      getSimilarityMaxX() {
        return Math.max(...this.traceStats.map((ts) => ts.ticks))
      },
      getSimilarityMinY() {
        return Math.min(...this.traceStats.map((ts) => ts.idle))
      },
      getSimilarityMaxY() {
        return Math.max(...this.traceStats.map((ts) => ts.idle))
      },
      toggleHelp() {
        console.log('toggleHelp')
        this.showHelp = !this.showHelp
        console.log(this.showHelp)
        this.renderSimilarityGraph()
      },
    },
    computed: {
      ...mapGetters([
        'playerTraces',
        'currentScreenshotURL',
        'tracesToShow',
        'traces',
        'nodeColors',
        'indexForLogId',
        'indexForEventId',
        'markAsRecommendation',
        'getRecommendedPlayersForCurrentEvent'
      ]),
      getSelectedTitle() {
        return 'Community'
      },
      getSelectedSubtitle() {
        if (this.selectedEventIdOtherPlayer === 0 || !this.tracesExist) {
          return 'Select an event node.'
        }
        return this.playerTraces[0].events[this.selectedEventIndexCurrentPlayer].top_text
      },
      getDirectionText() {
        if (this.selectedEventIdOtherPlayer === 0 || !this.tracesExist) {
          return 'No event node selected.'
        }
        const selectedLogId = this.playerTraces[this.selectedLogIndexOtherPlayer].log_id
        return this.playerTraces[0].events[this.selectedEventIndexCurrentPlayer].for_me_to_think
      },
      getSuggestionText() {
        if (this.selectedEventIdOtherPlayer === 0 || !this.tracesExist) {
          return 'Select an event node.'
        }
        const selectedLogId = this.playerTraces[this.selectedLogIndexOtherPlayer].log_id
        return this.playerTraces[0].events[this.selectedEventIndexCurrentPlayer].suggestions_from_other_players[selectedLogId]
      },
      getNoPlayerText() {
        return 'No Player Selected'
      },
      getSelectedPlayerName() {
        return `Player ${this.selectedLogIndexOtherPlayer}'s Solution'`
      },
      getSimilarityTitle() {
        return 'Community Graph: Thread Total Time vs Thread Wait Time'
      },
      getSimilarityLabels() {
        return [
          'Higher TTT',
          'Lower TTT',
          'Higher TWT',
          'Lower TWT'
        ]
      },
      getShowHelp() {
        return this.showHelp
      },
      getHelpText() {
        return ['This graph organizes the player community',
                'based on how each player\'s solution affected',
                'the threads (arrows) to complete their delivery',
                'tasks.',
                '',
                '(Thread Total Time vs. Thread Wait Time)',
                '',
                'Thread Total Time (TTT) refers to the amount of',
                'time it took this player\'s threads to complete',
                'their tasks.',
                '',
                'Thread Wait Time (TWT) refers to how long the',
                'threads remained blocked or idle.']
      },
      tracesExist() {
        return this.playerTraces.length
      },
      playerSelected() {
        if (this.selectedLogIndexOtherPlayer > 0) {
          return true
        }
        return false
      },
      traceWidth() {
        return Math.max(this.totalTraceWidth - this.margin.left - this.margin.right, 0)
      },
      traceHeight() {
        return Math.max(this.totalTraceHeight - this.margin.top - this.margin.bottom, 0)
      },
      similarityWidth() {
        return Math.max(this.totalSimilarityWidth, 0)
      },
      similarityHeight() {
        return Math.max(this.totalSimilarityHeight - 25, 0)
      },
      formattedTraces() {
        return this.playerTraces.map((trace, i) => {
          const add = i * 2
          return {
            log_id: trace.log_id,
            events: trace.events.map((event) => {
              const y = typeof event.ticks === 'number' ? event.ticks : 0
              return { ...event, y, yAdj: y + add }
            })
          }
        })
      },
      traceStats() {
        const stats = []
        for (let i = 0; i < this.playerTraces.length; i++) {
          const traceIndex = i
          const trace = this.playerTraces[traceIndex]
          for (let j = trace.events.length - 1; j >= 0; j--) {
            const eventIndex = j
            const event = trace.events[eventIndex]
            if (event.type === 'BOARD_SNAPSHOT'
              && event.idle !== null && event.idle !== 'null' && event.idle !== ''
              && event.idle !== null && event.ticks !== 'null' && event.idle !== ''
              && event.idle !== null && event.events !== 'null' && event.idle !== '') {
              stats.push({
                traceIndex,
                eventIndex,
                elements: Object.keys(event.absolute_board_state).length,
                ticks: event.ticks,
                idle: event.idle
              })
              break
            }
          }
        }
        return stats
      },
      lineGenerator() {
        return d3.line()
          .x((d, i) => this.xScale(i))
          .y((d) => this.yScale(d.yAdj))
      },
      maxX() {
        return this.getMaxXDimFromTraces()
      },
      maxY() {
        return this.getMaxYDimFromTraces()
      },
      xScale() {
        return d3.scaleLinear()
          .domain([0, this.getMaxXDimFromTraces()])
          .range([TRACE_LEFT_OFFSET, this.traceWidth - this.margin.right - TRACE_RIGHT_OFFSET])
      },
      yScale() {
        return d3.scaleLinear()
        // .domain specific to the data
        // .range is specific to the graph
          .domain([0, this.getMaxYDimFromTraces()])
          .range([this.margin.top + this.margin.bottom, this.traceHeight - TRACE_TOP_OFFSET])
      },
      similarityXScale() {
        const scale = d3.scaleLinear()
          .domain([this.getSimilarityMinX() - ((this.getSimilarityMaxX() - this.getSimilarityMinX()) / 10),
            this.getSimilarityMaxX() + (this.getSimilarityMaxX() - this.getSimilarityMinX()) / 10])
          .range([0, this.similarityWidth])
        return scale
      },
      similarityYScale() {
        const scale = d3.scaleLinear()
          .domain([this.getSimilarityMinY() - ((this.getSimilarityMaxY() - this.getSimilarityMinY()) / 10),
            this.getSimilarityMaxY() + ((this.getSimilarityMaxY() - this.getSimilarityMinY()) / 10)])
          .range([SIMILARITY_TOP_OFFSET, this.similarityHeight])
        return scale
      }
    },
  }

  function wrap(text, width) {
    text.each(function () {
      // eslint-disable-next-line no-var
      var text = d3.select(this)
      const words = text.text().split(/\s+/).reverse()
      let word = ''
      let line = []
      const lineNumber = 0
      const lineHeight = 1.1
      const y = text.attr('y')
      const dy = parseFloat(text.attr('dy'))
      let tspan = text.text(null).append('tspan')
        .attr('x', 0).attr('y', y)
        .attr('dy', `${dy}em`)
      word = words.pop()
      while (word !== null) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text.append('tspan')
          .attr('x', 0).attr('y', y)
          .attr('dy', `${lineHeight}em`)
          .text(word)
        }
      }
    })
  }
</script>

<style scoped>
  .parent {
    width:100vw;
    height:100vh;
    box-sizing: border-box;
    background-color: black;
    float: top;
  }
  .header {
    width: 100%;
    height: 5%;
    box-sizing: border-box;
    background-color: black;
    float: top;
  }
  .toggle-parent {
    width: 100%;
    height: 95%;
    background: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  .toggle-button-parent {
    width: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: start;
  }
  .toggle-button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .toggle-items {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .content-parent {
    width: 100%;
    height: 95%;
    box-sizing: border-box;
    background-color: black;
    float: top;
  }
  .player-view {
    width: 50%;
    height: 55%;
    background: #628184;
    border: 1px solid black;
    float: left;
  }
  .player-board-image {
    width:100%;
    height:100%;
    object-fit: contain;
  }
  .selected-view {
    width: 50%;
    height: 55%;
    background: white;
    border: 1px solid black;
    font-size: 1em;
    float: left;
  }
  .selected-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 600;
    height: 8%;
  }
  .selected-header-icon {
    width: 7%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selected-header-title {
    width: 93%;
    float: left;
  }
  .selected-subtitle {
    height: 8%;
    float: top;
    margin-top: 5px;
    border: 5px solid white;
    box-sizing: border-box;
  }
  .selected-board {
    width: 65%;
    height: 65%;
    margin: auto;
    float: top;
    background: #628184;
  }
  .selected-no-player {
    width: 100%;
    height: 100%;
    background: lightgrey;
    display: table;
    font-weight: 600;
    text-align: center;
  }
  .text-span {
    display: table-cell;
    vertical-align: middle;
  }
  .selected-name {
    width:100%;
    height:10%;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
  }
  .selected-board-image {
    width:100%;
    height:90%;
    object-fit: contain;
  }
  .selected-body {
    height: 19%;
    float: top;
    margin-top: 5px;
    border: 5px solid white;
    box-sizing: border-box;
    overflow-y:auto;
    font-size:1em;
  }
  .similarity-graph {
    width: 23%;
    height: 45%;
    background: white;
    border: 1px solid black;
    float: left;
  }
  .similarity-graph-title {
    margin-left: 10px;
    text-align: left;
  }
  .help-button {
    width: 100px;
    height: 100px;
  }
  .similarity-help {
    width: 66%;
    height: 50%;
    border: 1px solid black;
  }
  .help-text {
    width: 100%;
    height: 100%;
  }
  .similarity-graph-svg {
    width: 100%;
    height: 100%;
  }
  .trace-graph {
    width: 77%;
    height: 45%;
    background: white;
    border: 1px solid black;
    float: left;
  }
  .trace-key-svg {
    width: 100%;
    height: 6%;
  }
  .trace-graph-svg {
    width: 100%;
    height: 94%;
  }
</style>
