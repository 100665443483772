<template>
    <div style="padding:100px">
        <h1>Community Compare Canvas</h1>
      <div class="float-container">
          <div class="float-child">
          <h3>Viewport 1</h3>
            <h2>You</h2>
            <hr>
            <br>
          <div class="image-txt-container">
            <h2>{{this.selectedPlayerText}}</h2>
            <img style="width:700px;height:400px" :src='this.currentScreenshotURL(this.selectedLogIndexCurrentPlayer,this.selectedEventIdCurrentPlayer)'>
            </div>
          </div>
          <div class="float-child">
          <h3>Viewport 2</h3>
            <h2 v-if="selectedLogIndexOtherPlayer != 0">Player {{this.selectedLogIndexOtherPlayer}}</h2>
            <h2 v-else>No Player Selected</h2>
            <hr>
            <br>
            <div class="image-txt-container">
           <h2>{{this.recommendedPlayerText}}</h2>
            <img style="width:700px;height:400px" :src='this.currentScreenshotURL(this.selectedLogIndexOtherPlayer,this.selectedEventIdOtherPlayer)'>
            </div>
          </div>
        </div>
        <div class="row" style="padding-top:20px">
          <div class="col-sm-2"> <span><div class="circle_grey"></div></span>
            <b style="padding-left:23px;text-">Intermediate Move</b>
          </div>
          <div class="col-sm-2"> <span><div class="circle_green"></div></span>
            <b style="padding-left:23px;text-">Accurate Move</b>
          </div>
           <div class="col-sm-2"> <span><div class="circle_red"></div></span>
            <b style="padding-left:23px;">Inaccurte Move</b>
          </div>
           <div class="col-sm-2"> <span><div class="circle_yellow"></div></span>
            <b style="padding-left:23px">Recommended Move</b>
          </div>
        </div>

      <div id='player-graph-mount-node-linear' ref='playerGraphMountNodeLinear'>
        <svg v-if='tracesExist' :width='width' :height='height' id='player-graph-linear'></svg>
      </div>
    </div>
</template>
<script>
import * as d3 from 'd3'
import { range } from 'lodash'
import { graphColors } from '@/store/modules/opm'
import { mapGetters, mapActions } from 'vuex'

const GRAPH_OFFSET = 100
const NODE_RADIUS = 8

export default {
  name: 'ViewPortOPM',
  data() {
    return {
      selectedLogIndexCurrentPlayer: 0,
      selectedEventIdCurrentPlayer: 0,
      // selectedEventIndexCurrentPlyaer: 0,
      selectedLogIndexOtherPlayer: 0,
      selectedEventIdOtherPlayer: 0,

      selectedPlayerText: 'Click a node on your play trace to update the viewport. Click the (+) button to add another player to your play trace to see how others have handled a particular accurate or inaccurate move.',
      recommendedPlayerText: '',
      loadingTraces: false,
      totalHeight: 400,
      totalWidth: 500,
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      displayAllEventsForThesePlayers: [],
    }
  },
  methods: {
    ...mapActions([
      'getSetTraces',
      'addToShowTrace',
      'addToMarkAsRecommendation',
      'emptyMarkedRecommendations',
      'emptyTracesToShow',
      'setRecommendedPlayersForCurrentEvent',
      'showMessage'
    ]),
    getScreenshot(logIndex, eventId) {
        return this.currentScreenshotURL(logIndex, eventId)
    },
    colorSelect(move_classification, eventID) {
      if (this.markAsRecommendation.includes(eventID)) {
        return 'orange'
      }
      return this.nodeColors[move_classification]
    },
    renderTraces() {
          this.lineData = []

          d3.selectAll('svg > *').remove()
          const svg = d3.select('#player-graph-linear')
          console.log(svg)

            const xAxis = d3.axisBottom()
            .scale(this.xScale)
            .tickValues(range(0, this.maxX + 1, 1))

          console.log(this.xScale)

          svg.append('g')
            .attr('transform', `translate(0, ${this.height - GRAPH_OFFSET})`)
            .call(xAxis)
            .style('color', 'white')
          const yAxis = d3.axisLeft()
            .scale(this.yScale)
            .tickValues(range(0, this.maxY, 20))
            .tickSizeOuter(10)
          svg.append('g')
            .attr('transform', `translate(${GRAPH_OFFSET}, 0)`)
            .call(yAxis)
            .style('color', 'white')

          this.tracesToShow.forEach((logIndex, traces_to_show_index) => {
                for (let j = 0; j < this.playerTraces[logIndex].events.length; j++) {
                  let playerEvents = this.playerTraces[logIndex].events
                  if (!this.displayAllEventsForThesePlayers.includes(logIndex)) {
                    const indexOfSuggestedEvent = this.playerTraces[logIndex].events.findIndex((event) => {
                      if (this.colorSelect(event.move_classification, event.id) === 'orange') {
                        return true
                      }
                      return false
                    })

                    if (indexOfSuggestedEvent !== -1) {
                      playerEvents = this.playerTraces[logIndex].events.slice(indexOfSuggestedEvent - 2, indexOfSuggestedEvent + 3)
                    }
                  }

                  svg.append('g')
                  .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
                  .selectAll('.textlabel')
                  .data(this.playerTraces[logIndex].events)
                  .enter()
                  .append('text')
                  .attr('class', 'textlabel')
                  .style('font-family', 'Arial')
                  .text(`Player ${logIndex}`)
                  .attr('x', 0)
                  .attr('y', (d) => this.yScale(traces_to_show_index))

                  svg.append('g')
                  .attr('class', `player${logIndex}-g`)
                  .selectAll('node')
                  .data(playerEvents)
                  .enter()
                  .append('circle')
                  .style('fill', (d, i) => {
                    if (logIndex === 0 && d.move_classification !== 'ignore' && this.selectedEventIdCurrentPlayer === i) {
                      return 'blue'
                    }
                    return this.colorSelect(d.move_classification, d.id)
                  })
                  .attr('class', 'node')
                  .attr('cx', (d, i) => this.xScale(i))
                  .attr('cy', (d) => this.yScale(traces_to_show_index))
                  .attr('r', 10)
                  .on('click', (d, i) => {
                    // TODO : if I click on the current player play trace
                    if (this.selectedLogIndexCurrentPlayer === logIndex) {
                        // setting the Clicked node of current player
                        this.selectedEventIdCurrentPlayer = i
                        console.log(this.selectedEventIdCurrentPlayer, 'event id of current player you clicked')
                        // TODO : Clear Chart
                        // Clearing recommended events
                        this.emptyMarkedRecommendations()
                        // Clearing all traces
                        this.emptyTracesToShow()
                        console.log(d)
                        // Iterate through recommendations for the current event
                        // for (const [playerID, eventID] of Object.entries(d.recommended_players)) {
                        // console.log('playerid', playerID, '--', 'eventId', eventID)
                        // // TODO: get log index of players recommened and add them to traces to show
                        // this.addToShowTrace(this.indexForLogId(playerID))
                        // // TODO: add those to show yellow/something to let user pay attention
                        // this.addToMarkAsRecommendation(eventID)
                        // console.log('markAsRecommendation', this.markAsRecommendation)
                        // console.log('tracesToShow', this.tracesToShow)
                        // }
                      // TODO: Render canvas accordingly! (make sure you check also add the color part in the graph building part)
                      this.renderTraces()
                      if (d.recommended_suggestion !== '') {
                        this.selectedPlayerText = d.recommended_suggestion
                      } else {
                        this.selectedPlayerText = 'Click a node on your play trace to update the viewport. Click the (+) button to add another player to your play trace to see how others have handled a particular accurate or inaccurate move.'
                      }
                      this.recommendedPlayerText = ''
                      this.selectedLogIndexOtherPlayer = 0
                      this.selectedEventIdOtherPlayer = 0
                    } else { // clicking on the node from another player
                      console.log(d)
                      this.selectedLogIndexOtherPlayer = logIndex
                      // this.selectedEventIdOtherPlayer = i
                      this.selectedEventIdOtherPlayer = this.playerTraces[logIndex].events.findIndex((e) => e.id === d.id)
                      console.log(this.selectedLogIndexOtherPlayer, 'log idex of other player')
                      console.log(this.selectedEventIdOtherPlayer, 'event id other player')
                      console.log(this.selectedEventIdCurrentPlayer, 'event id current player')
                      this.selectedPlayerText = this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].for_me_to_think
                      // from the traces, get the current player, get the locked event in the current player
                      // .events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players
                      console.log(this.playerTraces[0].events[this.selectedEventIdCurrentPlayer])
                      for (const [playerID, correction] of Object.entries(this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players)) {
                        console.log('IN THE FOR LOOP before if')
                        if (this.indexForLogId(playerID) === logIndex && this.markAsRecommendation.includes(this.playerTraces[this.selectedLogIndexOtherPlayer].events[this.selectedEventIdOtherPlayer].id)) {
                          console.log(correction)
                          console.log('IN THE FOR LOOP')
                          this.recommendedPlayerText = correction
                        }
                      }
                    }
                  })
                }

                const newData = svg.select(`.player${logIndex}-g`).selectAll('.node').data()
                svg.select(`.player${logIndex}-g`).selectAll('.node').each((d, i, n) => {
                  newData[i].cx = d3.select(n[i]).attr('cx')
                  newData[i].cy = d3.select(n[i]).attr('cy')
                })

                svg.selectAll(`.player${logIndex}-g`)
                  .append('text')
                  .attr('class', 'show-full')
                  .text('Show Full')
                  .attr('x', Number(newData[newData.length - 1].cx) + 40)
                  .attr('y', Number(newData[newData.length - 1].cy))
                  .style('fill', () => {
                    console.log(this.displayAllEventsForThesePlayers)
                    if (this.displayAllEventsForThesePlayers.indexOf(logIndex) >= 0) {
                      return 'green'
                    }
                    return 'gray'
                  })
                  .style('cursor', 'pointer')
                  .on('click', () => {
                    const index = this.displayAllEventsForThesePlayers.indexOf(logIndex)
                    if (index < 0) {
                      this.displayAllEventsForThesePlayers.push(logIndex)
                    }
                    console.log(this.displayAllEventsForThesePlayers)
                    this.renderTraces()
                    // svg.selectAll(`.player${logIndex}-g`).selectAll('text').style('fill', 'green')
                  })

                svg.selectAll(`.player${logIndex}-g`)
                  .append('text')
                  .text('|')
                  .attr('x', Number(newData[newData.length - 1].cx) + 115)
                  .attr('y', Number(newData[newData.length - 1].cy))
                  .style('font-size', 20)
                  .style('fill', 'gray')
                  .style('cursor', 'pointer')

                svg.selectAll(`.player${logIndex}-g`)
                  .append('text')
                  .attr('class', 'minimize')
                  .text('Minimize')
                  .attr('x', Number(newData[newData.length - 1].cx) + 125)
                  .attr('y', Number(newData[newData.length - 1].cy))
                  .style('fill', 'gray')
                  .style('cursor', 'pointer')
                  .on('click', () => {
                    const index = this.displayAllEventsForThesePlayers.indexOf(logIndex)
                    if (index >= 0) {
                      // Remove the player from the list to render all events
                      this.displayAllEventsForThesePlayers.splice(index, 1)
                    }
                    this.renderTraces()
                    // svg.selectAll(`.player${logIndex}-g`).selectAll('text').style('fill', 'gray')
                  })

                const filteredNewData = newData.filter((node) => {
                  if (logIndex === 0) {
                    if (Object.keys(node.recommended_players).length !== 0) return 1
                    if (node.recommended_suggestion.length) return 1
                  }
                  if (this.markAsRecommendation.includes(node.id)) {
                    return 1
                  }
                  return 0
                })

                if (traces_to_show_index > 0 && traces_to_show_index === this.getRecommendedPlayersForCurrentEvent.length) {
                  filteredNewData.pop()
                }
                svg.append('g')
                  .selectAll('node')
                  .data(filteredNewData)
                  .enter()
                  .append('path')
                  .style('fill', (d) => this.colorSelect(d.move_classification, d.id))
                  .attr('class', 'node-expand')
                  .attr('d', 'M 17.18,11.56 C 17.18,11.56 11.55,11.56 11.55,11.56 11.55,11.56 11.55,17.18 11.55,17.18 11.55,17.18 6.44,17.18 6.44,17.18 6.44,17.18 6.44,11.56 6.44,11.56 6.44,11.56 0.82,11.56 0.82,11.56 0.82,11.56 0.82,6.44 0.82,6.44 0.82,6.44 6.44,6.44 6.44,6.44 6.44,6.44 6.44,0.82 6.44,0.82 6.44,0.82 11.55,0.82 11.55,0.82 11.55,0.82 11.55,6.44 11.55,6.44 11.55,6.44 17.18,6.44 17.18,6.44 17.18,6.44 17.18,11.56 17.18,11.56 Z')
                  .attr('transform', (d) => `translate(${Number(d.cx) - 9}, ${Number(d.cy) + 20})`)
                  .attr('stroke', '#000000')
                  .attr('opacity', (d, i) => {
                    if (logIndex === 0) {
                      if (Object.keys(d.recommended_players).length !== 0) return 1
                      if (d.recommended_suggestion.length) return 1
                    }
                    // renderTraces() is called after every click, so markAsrecommendation will be updated and a "+" will be visible
                    if (this.markAsRecommendation.includes(d.id)) {
                      return 1
                    }
                    return 0
                  })
                  .on('click', (d, i) => {
                    // TODO : if I click on the current player play trace
                    if (this.selectedLogIndexCurrentPlayer === logIndex) {
                        this.clickedNode = d
                        // setting the Clicked node of current player
                        this.selectedEventIdCurrentPlayer = this.indexForEventId(this.selectedLogIndexCurrentPlayer, d.id)
                        console.log(this.selectedEventIdCurrentPlayer, 'event id of current player you clicked + sign')
                        // TODO : Clear Chart
                        // Clearing recommended events
                        this.emptyMarkedRecommendations()
                        // Clearing all traces
                        this.emptyTracesToShow()
                        this.displayAllEventsForThesePlayers = []
                        this.indexTrack = 0
                        // Iterate through recommendations for the current event
                        const recommendedPlayers = Object.entries(d.recommended_players).map(([playerID, eventID]) => ({ playerID, eventID }))
                        this.setRecommendedPlayersForCurrentEvent(recommendedPlayers)
                        const { playerID, eventID } = recommendedPlayers[this.indexTrack]
                        this.indexTrack += 1
                        if (this.indexTrack === recommendedPlayers.length) this.showMessage('All Recommended Players are shown')
                        // TODO: get log index of players recommened and add them to traces to show
                        this.addToShowTrace(this.indexForLogId(playerID))
                        // TODO: add those to show yellow/something to let user pay attention
                        this.addToMarkAsRecommendation(eventID)
                      // TODO: Render canvas accordingly! (make sure you check also add the color part in the graph building part)
                      this.renderTraces()
                      // this.selectedPlayerText = d.recommended_suggestion
                    } else { // If clicking on the + sign which is not the current player
                      const recommendedPlayers = this.getRecommendedPlayersForCurrentEvent
                      if (this.indexTrack < recommendedPlayers.length && this.tracesToShow[this.tracesToShow.length - 1] === logIndex) {
                        const { playerID, eventID } = recommendedPlayers[this.indexTrack]
                        this.addToShowTrace(this.indexForLogId(playerID))
                        this.indexTrack += 1
                        if (this.indexTrack === recommendedPlayers.length) this.showMessage('All Recommended Players are shown')
                        // TODO: add those to show yellow/something to let user pay attention
                        this.addToMarkAsRecommendation(eventID)
                        this.renderTraces()
                        this.selectedPlayerText = d.recommended_suggestion
                        // from the traces, get the current player, get the locked event in the current player
                        // console.log(this.selectedEventIdCurrentPlayer)
                        console.log(this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players)
                        for (const [playerID, correction] of Object.entries(this.playerTraces[this.selectedLogIndexCurrentPlayer].events[this.selectedEventIdCurrentPlayer].suggestions_from_other_players)) {
                          if (this.indexForLogId(playerID) === logIndex) {
                            this.recommendedPlayerText = correction
                          }
                        }
                      }
                    }
                  })
                })
                const recommendedPlayers = this.getRecommendedPlayersForCurrentEvent
                if (recommendedPlayers.length) {
                  this.lineData.push(this.clickedNode)
                  this.tracesToShow.forEach((player, index) => {
                    if (player !== 0) {
                      const { eventID } = recommendedPlayers[index - 1] // This starts at a diff index since this is a structure that doesn't include the original curr player event node
                      console.log(this.playerTraces[player].events) // We get player 1, 2, or 7, from playerTraces, this is a diff index
                      console.log(eventID)
                      this.lineData.push(this.playerTraces[player].events.filter((event) => event.id === eventID)[0])
                    }
                  })
                }

                console.log(this.lineData)
                if (this.lineData) {
                  svg.append('g')
                    .selectAll('node')
                    .data(this.lineData)
                    .enter()
                    .append('line')
                    .attr('class', 'node-line')
                    .attr('x1', (d, i) => d.cx)
                    .attr('x2', (d, i) => {
                      if (i < this.lineData.length - 1) return this.lineData[i + 1].cx
                      // Create a non existing line for the last recommended node (basically drawing to itself)
                      return d.cx
                    })
                    .attr('y1', (d, i) => d.cy)
                    .attr('y2', (d, i) => {
                      if (i < this.lineData.length - 1) return this.lineData[i + 1].cy
                      // Create a non existing line for the last recommended node (basically drawing to itself)
                      return d.cy
                    })
                    .attr('stroke', '#000000')
                }
            },
    getMaxXDimFromTraces() {
      return Math.max(...this.formattedTraces.map((ft) => ft.events.length))
    },
     getMaxYDimFromTraces() {
      return this.tracesToShow.length
    },
    async init() {
      this.loadingTraces = true
      try {
        console.log('try')
        await this.getSetTraces()
        console.log(this.playerTraces)
      } catch (e) {
        console.log('catch')
        this.showMessage(e)
      } finally {
        console.log('finally')
        this.loadingTraces = false
      }
      console.log('looping')
      console.log(this.playerTraces.length)
      for (let i = 0; i < this.playerTraces.length; i++) {
        if (this.playerTraces[i].is_current_user) {
          console.log(i)
          console.log('the above player is the current player')
          this.selectedLogIndexCurrentPlayer = i
          } else {
          console.log(i)
          console.log('not the current player')
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'playerTraces',
      'currentScreenshotURL',
      'tracesToShow',
      'traces',
      'nodeColors',
      'indexForLogId',
      'indexForEventId',
      'markAsRecommendation',
      'getRecommendedPlayersForCurrentEvent'
    ]),
     tracesExist() {
      return this.playerTraces.length
    },
    width() {
      return Math.max(this.totalWidth - this.margin.left - this.margin.right, 0)
    },
    height() {
      return Math.max(this.totalHeight - this.margin.top - this.margin.bottom, 0)
    },
    formattedTraces() {
      return this.playerTraces.map((trace, i) => {
        const add = i * 2
        return {
          log_id: trace.log_id,
          events: trace.events.map((event) => {
            const y = typeof event.ticks === 'number' ? event.ticks : 0
            return { ...event, y, yAdj: y + add }
          })
        }
      })
    },
    lineGenerator() {
      return d3.line()
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d.yAdj))
    },
    maxX() {
      return this.getMaxXDimFromTraces()
    },
    maxY() {
      return this.getMaxYDimFromTraces()
    },
    xScale() {
      return d3.scaleLinear()
        .domain([0, this.getMaxXDimFromTraces()])
        .range([GRAPH_OFFSET, this.width - this.margin.right])
    },
    yScale() {
      return d3.scaleLinear()
      // .domain specific to the data
      // .range is specific to the graph
        .domain([this.getMaxYDimFromTraces(), 0])
        .range([this.margin.top + this.margin.bottom, this.height - GRAPH_OFFSET])
    },
  },
  async mounted() {
    await this.init()
    this.totalWidth = this.$refs.playerGraphMountNodeLinear.clientWidth
    this.totalHeight = this.$refs.playerGraphMountNodeLinear.clientHeight
    this.renderTraces()
  },
}

</script>
<style scoped>
 .node {
    cursor: pointer;
  }
  #player-graph-mount-node-linear {
    min-height: 600px;
    width: 100%;
    padding-top: 100px;
  }

  .float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid black;
}
.image-txt-container {
  display:flex;
  align-items:center;
  flex-direction: row;
}
.circle_green {
    width:30px;
    height:30px;
    border-radius:50px;
    font-size:30px;
    color:#fff;
    line-height:100px;
    background: green;
}
.circle_yellow {
    width:30px;
    height:30px;
    border-radius:50px;
    font-size:30px;
    color:#fff;
    line-height:100px;
    background: orange;
}
.circle_red {
    width:30px;
    height:30px;
    border-radius:50px;
    font-size:30px;
    color:#fff;
    line-height:99px;
    background: red;
}

.circle_grey {
    width:30px;
    height:30px;
    border-radius:50px;
    font-size:30px;
    color:#fff;
    line-height:99px;
    background: #e0e0e0;
}

.col-sm-2 > span, .col-sm-2 > p {
  display: inline-block;
}
</style>
