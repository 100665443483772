<template>
  <div class='action-bar' @mousemove='mouseMove' @mouseup='mouseUp'>
    <KeyMap class='no-select'/>
    <div class="playback-buttons">
      <v-btn
        class='no-select playback-btn reset-btn mr-3'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => stopSimulation()"
      >Reset</v-btn>
      <v-btn
        v-if='!isSimulationPaused'
        class='no-select playback-btn pause-play-btn mr-3'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => pauseSimulation()"
      ><v-icon class='action-btn-icon'>mdi-pause</v-icon>Pause</v-btn>
      <v-btn
        v-else
        class='no-select playback-btn pause-play-btn mr-3'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => unpauseSimulation()"
      ><v-icon class='action-btn-icon'>mdi-play</v-icon>Play</v-btn>
      <v-btn
        class='no-select playback-btn'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => skipSimulation()"
      ><v-icon class='playback-btn-icon'>mdi-skip-next</v-icon>Skip</v-btn>
    </div>
    <div class='simulation-buttons'>
      <v-btn
        id='TEST_BUTTON'
        class='no-select mr-4'
        @click.prevent="() => handleSimulation('TEST')"
        :disabled='isSimulationActive'
        :color='getActionBtnColor(isTestDisabled())'
        :large="$vuetify.breakpoint.lgAndUp"
      ><v-icon class='action-btn-icon'>{{getActionBtnIcon(isTestDisabled())}}</v-icon>TEST</v-btn>
      <v-btn
        id='SUBMIT_BUTTON'
        class='no-select'
        :large="$vuetify.breakpoint.lgAndUp"
        :disabled='isSimulationActive'
        @click.prevent="() => handleSimulation('SUBMIT')"
        :color='getActionBtnColor(isSubmitDisabled())'
      ><v-icon class='action-btn-icon'>{{getActionBtnIcon(isSubmitDisabled())}}</v-icon>SUBMIT</v-btn>
      <div class='action-divider'></div>
      <v-btn
        id='BOARD_SNAPSHOT'
        class='no-select'
        :large="$vuetify.breakpoint.lgAndUp"
        @click.prevent="() => handleBoardSnapshot()"
        :color='getBoardSnapshotBtnColor( )'
      ><v-icon class='action-btn-icon'>{{getBoardSnapshotBtnIcon(false)}}</v-icon>BOARD SNAPSHOT</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { actionType, elementType } from '@/vars'
import KeyMap from '@/components/level/KeyMap.vue'

export default {
  name: 'ActionBar',
  data() {
    return {
      draggedEl: null,
      startCell: null
    }
  },
  computed: {
    ...mapGetters([
      'isSimulationEmpty',
      'reflectionContent',
      'isSimulationActive',
      'isSimulationPaused',
      'hasTestBeenRunSuccessfully',
      'shouldCheckTutorialRequiredAction',
      'isSimulationRequiredForTutorialStep',
      'hasReflectionContentBeenSet',
      'isTutorialActive',
      'isDraggingElement',
      'select',
      'selectByCoords',
      'draggedElementPosition',
      'traceData',
      'hasBoardChanged',
      'linkElements',
      'hintElements',
      'hintsShown',
      'currentScreenshotURL',
      'zones',
      'userPlacedElements',
      'linkElements',
      'contentArray',
      'initialIndex',
      'goodCount',
      'badCount'
      ]),
    isCommunityDisabled() {
      if (this.traceData && !this.hasBoardChanged && !this.isSimulationActive && this.getTotalMoves > 0) {
        return false
      }
      return true
    },
    getTotalMoves() {
      return this.goodCount + this.badCount
    },
  },
  components: {
    KeyMap
  },
  async mounted() {
    this.disableCommunity()
    this.clearCommunityContent()
  },
  methods: {
    ...mapActions([
      'showMessage',
      'runSimulation',
      'activateReflectionDialog',
      'pauseSimulation',
      'skipSimulation',
      'unpauseSimulation',
      'stopSimulation',
      'checkTutorialRequiredAction',
      'draw',
      'setDraggedElementPosition',
      'setIsDraggingElement',
      'getSetTraceData',
      'getBoardSnapshotData',
      'createHint',
      'removeElement',
      'setHintsShown',
      'activateCommunityDialog',
      'deactivateCommunityDialog',
      'setCommunityContent',
      'clearCommunityContent',
      'uploadLogData',
      'activateHint',
    ]),
    mouseUp(e) {
      this.startCell = this.draggedElementPosition
      const el = this.select(e.y, e.x)
      this.draggedEl = el
      if (el) {
        this.draggedEl.coordinates = [e.y, e.x]
        if (!this.isSimulationActive && this.isDraggingElement) {
            this.setIsDraggingElement(false)
            this.returnDraggedEl()
            this.showMessage('Elements must be placed on path!')
        }
        this.draw()
      }
    },
    mouseMove(e) {
      const { y, x } = e
      const el = this.select(y, x)
      // If an element is being dragged
      if (this.isDraggingElement) {
        this.draggedEl = el
        this.draggedEl.coordinates = [y, x]
      }
      this.draw()
    },
    returnDraggedEl() {
      this.draggedEl.cell = this.startCell
      this.startCell = null
      this.setDraggedElementPosition(null)
      this.resetDraggedEl()
    },
    resetDraggedEl() {
      this.draggedEl = null
    },
    handleSimulation(type) {
      this.logAndTrace()
      this.destroyHints()
      this.deactivateCommunityDialog()
      if (type === 'SUBMIT' && !this.hasTestBeenRunSuccessfully) {
        this.showMessage('You must complete a successful test before submitting.')
      }
      if (type === 'SUBMIT' && this.isSubmitDisabled()) return
      if (type === 'TEST' && this.isTestDisabled()) return
      if (this.isSimulationRequiredForTutorialStep) {
        this.runSimulation(type)
      } else if (this.shouldCheckTutorialRequiredAction) {
        const target = type === actionType.TEST ? elementType.TEST_BUTTON : elementType.SUBMIT_BUTTON
        this.checkTutorialRequiredAction({ type: actionType.BUTTON_CLICK, target })
      } else {
        this.runSimulation(type)
      }
    },
    handleReflection() {
      if (!this.isSimulationActive) {
        this.activateReflectionDialog()
      } else {
        this.showMessage('You must fully complete a test or submission to get reflection content.')
      }
    },
    async handleBoardSnapshot() {
      const res = await this.getBoardSnapshot()
      // console.log(JSON.stringify(res, null, 2))
      navigator.clipboard.writeText(JSON.stringify(res, null, 2))
      this.showMessage('Board snapshot copied to clipboard.')
    },
    // toggles our community view on and off by checking to see if hints are being shown
    async handleCommunity() {
      if (this.hintsShown) {
        this.disableCommunity()
      } else {
        this.enableCommunity()
      }
    },
    async disableCommunity() {
      this.destroyHints()
      this.deactivateCommunityDialog()
      this.setCommunityContent(this.contentArray[this.initialIndex])
    },
    async enableCommunity() {
      // content.media = this.currentScreenshotURL(playerIndex, eventIndex)
      await this.createHints(this.contentArray)
      this.activateCommunityDialog()
      await this.activateHint(this.initialIndex)
      this.setCommunityContent(this.contentArray[this.initialIndex])
      this.draw()
    },
    getActionBtnColor(flag) {
      return flag ? 'red' : 'green'
    },
    getSubmitIcon() {
      return this.isSubmitDisabled() ? 'mdi-lock' : 'mdi-lock-open-outline'
    },
    getActionBtnIcon(flag) {
      return flag ? 'mdi-lock' : 'mdi-lock-open-outline'
    },
    getCommunityBtnColor(flag) {
      return !flag ? 'yellow' : 'gray'
    },
    getCommunityBtnIcon(flag) {
      return !flag ? 'mdi-lightbulb-on-outline' : 'mdi-lightbulb-outline'
    },
    getBoardSnapshotBtnColor(flag) {
      return !flag ? 'gray' : 'gray'
    },
    getBoardSnapshotBtnIcon(flag) {
      return !flag ? 'mdi-camera' : 'mdi-camera-outline'
    },
    isSubmitDisabled() {
      if (!this.hasTestBeenRunSuccessfully) {
        return true
      }
      if (this.isSimulationActive) {
        return true
      }
      return false
    },
    isTestDisabled() {
      if (this.isSimulationActive) {
        return true
      }
      return false
    },
    isReflectionDisabled() {
      if (this.isTutorialActive) {
        return true
      }
      if (!this.hasReflectionContentBeenSet) {
        return true
      }
      if (this.isSimulationActive) {
        return true
      }
      return false
    },
    async logAndTrace() {
      await this.uploadLog()
      // await this.getTraceData()
    },
    async uploadLog() {
      const res = await this.uploadLogData()
    },
    async getBoardSnapshot() {
      const res = await this.getBoardSnapshotData()
      return res
    },
    async getTraceData() {
      await this.getSetTraceData(this.$route.params.level)
    },
    async createHints(contentArray) {
      const links = this.linkElements
      for (let i = 0; i < contentArray.length; i++) {
        const content = contentArray[i]
        for (let j = 0; j < links.length; j++) {
          const link = links[j]
          if (link.data.id === content.element) {
            const data = {}
            data.link = link
            data.content = content
            data.index = i
            this.createHint(data)
            break
          }
        }
      }
      this.setHintsShown(true)
      this.draw()
    },
    async destroyHints() {
      const hints = this.hintElements
      hints.forEach(async (hint) => {
        this.removeElement(hint)
      })
      this.setHintsShown(false)
      this.draw()
    },
    compare(a, b) {
      if (a === b) return true
      if (a.length !== b.length) return false
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style scoped>
.action-bar {
  height: 100px;
  width: 100%;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;
}
.simulation-buttons {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.action-btn-icon {
  margin-right: 0.5rem;
}
.action-divider {
  height: 25px;
  margin: 0 1.5rem;
  border: 1px solid rgb(65, 65, 65);
}

.pause-play-btn {
  width: 100px;
}
.pause-play-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.playback-buttons {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.playback-btn-icon {
  margin-right: 0.2rem;
}
</style>
