/* eslint-disable no-shadow */
import Vue from 'vue'
import { logEventTypes } from '@/store/modules/logEvents'

const getDefaultState = () => ({
  reflectionContent: {
    description: '',
    header: '',
    goals: [],
    numUserPlacedComponents: null,
    reflections: [],
    simType: '',
    status: '',
    subtitle: '',
    title: '',
    titleIcon: '',
    suggestions: ''
  },
  isReflectionDialogActive: false
})

const state = getDefaultState()
const getters = {
  reflectionContent: (state) => state.reflectionContent,
  suggestions: (state) => state.suggestions,
  hasReflectionContentBeenSet: (state) => !!state.reflectionContent.title && !!state.reflectionContent.subtitle,
  isReflectionDialogActive: (state) => state.isReflectionDialogActive
}
const actions = {
  setReflectionContent: async ({ commit, dispatch }, reflectionContent) => {
    await dispatch('addLogEvent', { type: logEventTypes.SET_REFLECTION_CONTENT, content: reflectionContent })
    commit('setReflectionContent', reflectionContent)
  },
  setSuggestions: async ({ commit, dispatch }, suggestions) => {
    // await dispatch('addLogEvent', { type: logEventTypes.SET_REFLECTION_CONTENT, content: reflectionContent })
    commit('setSuggestions', suggestions)
  },
  activateReflectionDialog: ({ commit }) => {
    commit('activateReflectionDialog')
  },
  deactivateReflectionDialog: ({ commit }) => {
    commit('deactivateReflectionDialog')
  },
  destroyReflection({ commit }) {
    commit('destroyReflection')
  }
}
const mutations = {
  activateReflectionDialog: (state) => {
    state.isReflectionDialogActive = true
  },
  deactivateReflectionDialog: (state) => {
    // TODO: make content available after dialog is muted
    state.isReflectionDialogActive = false
    // Object.assign(state, getDefaultState())
  },
  setReflectionContent: (state, payload) => {
    Vue.set(state, 'reflectionContent', payload)
  },
  setSuggestions: (state, payload) => {
    Vue.set(state, 'suggestions', payload)
  },
  destroyReflection: (state) => {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
