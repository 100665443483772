/* eslint-disable radix */
/* eslint-disable no-param-reassign */

///
/// COMPONENT LOGIC
///

import { getDirection, getComponent, getDeliveredPackageIndex, createAction } from './boardUtils'
import { components, pickups } from './boardEnums'
import { elementType } from '../vars'

/**
 * pickup() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and a pickup component
 */
export const pickupThreadInteraction = (obj, board, threads, thread, tIndex, cell, pickup, cIndex, timestep, idGenerator) => {
  let pickupMutations = {}
  const packageId = cell.components[cIndex] + pickup.picked
  pickup.passed += 1
  if (pickup.available == null || pickup.available > 0 || pickup.spec === pickups.UNLIMUNCONDITION) {
    if (thread.payload == null) {
      thread.payload = []
    }

    pickup.picked += 1
    if (pickup.spec !== pickups.UNLIMUNCONDITION) {
      pickup.available = 0
    } else {
      pickup.available = 1
    }

    pickupMutations = { available: pickup.available, picked: pickup.picked }
    board.components[packageId] = {
      id: packageId,
      pickup: cell.components[cIndex],
      cell: thread.cell,
    }

    thread.payload.push({ id: packageId, pickup: cell.components[cIndex] })
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: threads[tIndex], type: elementType.THREAD },
      { payload: thread.payload.slice() },
    ))
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: packageId, type: elementType.PACKAGE },
      {
        delivered: false,
        cell: board.components[packageId].cell,
        spec: pickup.spec,
        thread: threads[tIndex],
        pickup: cell.components[cIndex],
      },
    )) // package, thread id, pickup
  }

  pickupMutations.passed = pickup.passed
  obj.actions.push(createAction(
    idGenerator,
    'E', timestep,
    { id: cell.components[cIndex], type: elementType.PICKUP },
    pickupMutations,
  ))
}

/**
 * delivery() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and a delivery component
 */
export const deliveryThreadInteraction = (obj, board, threads, thread, threadIndex, cell, delivery, deliveryIndex, timestep, idGenerator) => {
  const deliveryMutations = {}
  let deliverySuccess = false
  let deliveryFailure = false
  delivery.passed += 1
  deliveryMutations.passed = delivery.passed
  if (thread.payload != null) {
    const indices = getDeliveredPackageIndex(thread, delivery, board.components)
    for (let i = indices.length - 1; i >= 0; i--) {
      const index = indices[i]
      if (delivery.busy === false) {
        deliverySuccess = true
        const delivered = thread.payload[index]
        board.components[delivered.pickup].available += 1
        thread.payload.splice(index, 1)
        if (delivery.delivered == null) {
          delivery.delivered = 0
        }

        thread.delivered += 1
        delivery.delivered += 1
        deliveryMutations.delivered = delivery.delivered
        obj.actions.push(createAction(
          idGenerator,
          'E',
          timestep,
          { id: threads[threadIndex], type: elementType.THREAD },
          { payload: thread.payload.slice(), delivered: thread.delivered },
        ))
        obj.actions.push(createAction(
          idGenerator,
          'E',
          timestep,
          { id: delivered.id, type: elementType.PACKAGE },
          { delivered: true },
        ))
        obj.actions.push(createAction(
          idGenerator,
          'D',
          timestep,
          { id: cell.components[deliveryIndex], type: elementType.DELIVERY },
          deliveryMutations,
        ))
        obj.actions.push(createAction(
          idGenerator,
          'E',
          timestep,
          { id: delivered.pickup, type: elementType.PICKUP },
          { available: board.components[delivered.pickup].available },
        ))
      } else {
        deliveryFailure = true
      }
    }
  }

  if (deliverySuccess) {
    delivery.busy = true
  } else if (deliveryFailure) {
    thread.missed += 1
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: threads[threadIndex], type: elementType.THREAD },
      { missed: thread.missed },
    ))
    obj.actions.push(createAction(
      idGenerator,
      'D',
      timestep,
      { id: cell.components[deliveryIndex], type: elementType.DELIVERY },
      deliveryMutations,
    ))
  }
}

/**
 * signal() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and a signal component
 */
export const signalThreadInteraction = (obj, board, cell, signal, signalIndex, timestep, idGenerator) => {
  obj.actions.push(createAction(
    idGenerator,
    'E',
    timestep,
    { id: cell.components[signalIndex], type: elementType.SIGNAL },
    { passed: signal.passed },
  ))
  if (signal.link !== '' && board.components[signal.link] != null) {
    if (board.components[signal.link].type === components.SEMAPHORE) {
      board.components[signal.link].spec = 'active'
      obj.actions.push(createAction(
        idGenerator,
        'E', timestep,
        { id: signal.link, type: board.components[signal.link].type },
        { spec: 'active' },
      ))
    } else if (board.components[signal.link].type === components.SWITCH) {
      board.components[signal.link].current += 1
      if (board.components[signal.link].current
        >= board.components[signal.link].directions.length) {
        board.components[signal.link].current = 0
      }

      obj.actions.push(createAction(
        idGenerator,
        'E',
        timestep,
        { id: signal.link, type: board.components[signal.link].type },
        { current: board.components[signal.link].current },
      ))
    }
  }
}

/**
 * semaphore() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and a semaphore component
 */
export const semaphoreThreadInteraction = (obj, cell, semaphore, semaphoreIndex, timestep, idGenerator) => {
  semaphore.spec = 'inactive'
  semaphore.passed += 1
  obj.actions.push(createAction(
    idGenerator,
    'E',
    timestep,
    { id: cell.components[semaphoreIndex], type: elementType.SEMAPHORE },
    { passed: 1, spec: 'inactive' },
  ))
}

/**
 * f_switch() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and an switch component
 */
const switchThreadInteraction = (obj, cell, _switch, switchIndex, timestep, idGenerator) => {
  _switch.passed += 1
  obj.actions.push(createAction(
    idGenerator,
    'E',
    timestep,
    { id: cell.components[switchIndex], type: elementType.SWITCH },
    { passed: 1 },
  ))
}

/**
 * conditional() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and an conditional component
 */
const conditionalThreadInteraction = (obj, cell, conditional, conditionalIndex, timestep, idGenerator) => {
  conditional.passed += 1
  obj.actions.push(createAction(
    idGenerator,
    'E',
    timestep,
    { id: cell.components[conditionalIndex], type: elementType.CONDITIONAL },
    { passed: 1 },
  ))
}

/**
 * exchange() makes changes to the board state,
 * and creates actions based on the interaction
 * between a thread component and an exchange component
 */
export const exchangeThreadInteraction = (obj, board, threads, thread, threadIndex, cell, exchange, exchangeIndex, timestep, idGenerator) => {
  exchange.value = 1
  exchange.thread = threads[threadIndex]
  exchange.passed += 1
  thread.stopped = true
  const componentMutations = { passed: exchange.passed }
  if (board.components[exchange.link].value === 1) {
    exchange.exchanged += 1
    const payloadA = thread.payload.slice()
    const payloadB = board.components[board.components[exchange.link].thread].payload.slice()
    thread.payload = payloadB
    board.components[board.components[exchange.link].thread].payload = payloadA
    exchange.value = 0
    board.components[exchange.link].value = 0
    thread.stopped = false
    board.components[board.components[exchange.link].thread].stopped = false
    componentMutations.exchanged += 1
    board.components[exchange.link].exchanged += 1
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: cell.components[exchangeIndex], type: elementType.SIGNAL },
      componentMutations,
    ))
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: exchange.link, type: elementType.SIGNAL },
      { exchange: board.components[exchange.link] },
    ))
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: threads[threadIndex], type: elementType.THREAD },
      { payload: thread.payload.slice() },
    ))
    obj.actions.push(createAction(
      idGenerator,
      'E', timestep,
      { id: board.components[exchange.link].thread, type: elementType.THREAD },
      {
        payload:
          board.components[board.components[exchange.link].thread]
            .payload.slice(),
      },
    ))
  } else {
    obj.actions.push(createAction(
      idGenerator,
      'E',
      timestep,
      { id: cell.components[exchangeIndex], type: elementType.SIGNAL },
      componentMutations,
    ))
  }
}

/**
 * thread() logic for a thread moving through the board
 * triggers functions for interacting with various components
 */
export const threadMovementLogic = (obj, board, threads, thread, threadIndex, timestep, idGenerator) => {
  const currentCell = board.map[thread.cell[0]][thread.cell[1]]
  let next = null
  // eslint-disable-next-line radix
  let direction = parseInt(thread.direction)
  if (Number.isNaN(direction)) {
    direction = 1
  }
  let index = 0
  if (!Array.isArray(currentCell.directions)) {
    currentCell.directions = [currentCell.directions]
  }

  if (typeof currentCell.directions[0] !== 'number') {
    return new Error('Cell directions are non-number values.')
  }

  const conditional = getComponent(board, currentCell, components.CONDITIONAL)
  const cSwitch = getComponent(board, currentCell, components.SWITCH)
  if (conditional) {
    direction = conditional.directionDefault

    for (let iA = 0; iA < conditional.directionsColors.length; iA += 1) {
      if (conditional.directionsColors[iA].length > 0
        && conditional.directionsColors[iA].includes(thread.color)) {
        direction = iA + 1
      }
    }

    for (let iB = 0; iB < conditional.directionsTypes.length; iB += 1) {
      if (conditional.directionsTypes[iB].length > 0) {
        if (thread.payload.length > 0) {
          let valid = false
          for (let iC = 0; iC < thread.payload.length; iC += 1) {
            if (conditional.directionsTypes[iB]
              .includes(board.components[thread.payload[iC].pickup].spec)) {
              valid = true
            } else {
              valid = false
              break
            }
          }
          if (valid) {
            direction = iB + 1
          }
        } else if (conditional.directionsTypes[iB]
          .includes('emptyPayload')) {
          direction = iB + 1
        }
      }
    }

    next = getDirection(direction, board.map, thread.cell)
    if (next.color.indexOf(thread.color) === -1) {
      next = null
    }
  } else if (cSwitch) {
    direction = cSwitch.directions[cSwitch.current]
    next = getDirection(direction, board.map, thread.cell)
    if (next.color.indexOf(thread.color) === -1) {
      next = null
    }
  } else {
    let tries = 0
    while ((next == null || next === {} || next.layout == null) && tries < 10) {
      // if direction is too high or low, set to 1
      if (direction < 1 || direction > 4) {
        direction = 1
      }

      // is the direction valid for this cell
      if (currentCell.directions.includes(direction)) {
        next = getDirection(direction, board.map, thread.cell)
        if (next != null && next !== {} && next.layout != null) {
          if (next.color.indexOf(thread.color) !== -1) {
            break
          } else {
            next = null
          }
        }
      }

      if (next == null || next === {} || next.layout == null) {
        direction += 1
        /*  if (direction === parseInt(thread.direction)) {
          // explicit failure state for a thread making a wrong turn/reaching
          // a dead end
          return {
            failure: true,
            cause: `Thread ${thread.id} has been told to make an incorrect turn.`,
          }
        } */
      }

      tries += 1
      if (tries >= 4) {
        //  this is probably an error if no valid directions exist, see commented out code above
        break
      }
    }
  }

  let blocked = false

  if (next != null && next.layout != null) {
    // see if there is a semaphore blocking our intended path
    if (next.components.length > 0) {
      for (let iE = 0; iE < next.components.length; iE += 1) {
        if (board.components[next.components[iE]].type === components.SEMAPHORE) {
          if (board.components[next.components[iE]].spec !== 'active') {
            blocked = true
          }
        }
      }
    }

    if (!blocked) {
      // create the thread movement action
      const prevCell = thread.cell
      thread.cell = [next.coords[0], next.coords[1]]
      const threadMutations = {}
      threadMutations.cell = [thread.cell[0], thread.cell[1]]
      if (parseInt(thread.direction) !== direction) {
        threadMutations.direction = direction
      }

      thread.direction = direction
      obj.actions.push(createAction(
        idGenerator,
        'M',
        timestep,
        { id: threads[threadIndex], type: elementType.THREAD },
        threadMutations,
      ))
      // change cell component ids
      currentCell.components.splice(
        currentCell.components.indexOf(threads[threadIndex]),
        1,
      )
      next.components.push(threads[threadIndex])
      thread.starvation = 0
      thread.budget -= 1

      // move packages
      if (thread.payload != null && thread.payload) {
        for (index = thread.payload.length - 1; index >= 0; index -= 1) {
          if (index === 0) {
            obj.actions.push(createAction(
              idGenerator,
              'M',
              timestep,
              { id: thread.payload[index].id, type: elementType.PACKAGE },
              { cell: prevCell },
            ))
            board.components[thread.payload[index].id].cell = prevCell
          } else {
            obj.actions.push(createAction(
              idGenerator,
              'M',
              timestep,
              { id: thread.payload[index].id, type: elementType.PACKAGE },
              { cell: board.components[thread.payload[index - 1].id].cell },
            ))
            board.components[thread.payload[index].id].cell = board.components[thread.payload[index - 1].id].cell
          }
        }
      }

      // handle reached component(s)
      if (next.components.length !== 0) {
        for (index = 0; index < next.components.length; index += 1) {
          if (board.components[next.components[index]] == null) {
            return new Error('Map cell has an invalid component id.')
          }

          const component = board.components[next.components[index]]
          let result = null
          switch (component.type) {
            case elementType.PICKUP:
              result = pickupThreadInteraction(
                obj,
                board,
                threads,
                thread,
                threadIndex,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.DELIVERY:
              result = deliveryThreadInteraction(
                obj,
                board,
                threads,
                thread,
                threadIndex,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.SIGNAL:
              result = signalThreadInteraction(
                obj,
                board,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.SEMAPHORE:
              result = semaphoreThreadInteraction(
                obj,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.SWITCH:
              result = switchThreadInteraction(
                obj,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.CONDITIONAL:
              result = conditionalThreadInteraction(
                obj,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.EXCHANGE:
              result = exchangeThreadInteraction(
                obj,
                board,
                threads,
                thread,
                threadIndex,
                next,
                component,
                index,
                timestep,
                idGenerator,
              )
              break

            case elementType.THREAD:
              if (component.id !== thread.id) {
                // currently, threads do not interact
              }
              break

            default:
              // unexpected component type
              // won't cause problems, but should be fixed
              break
          }

          if (result && result.stack && result.message) {
            return result
          } if (result && result.failure) {
            return result
          }
        }
      }
    } else {
      thread.budget = 0
      thread.starvation += 1
      thread.totalWait += 1
    }
  } else {
    thread.deadend = 1
  }
  return null
}
