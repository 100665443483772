<template>
  <div class='opm-view'>
    <div id="opm-header"></div>
    <div class='opm-content'>
      <div id='left-ctr'>
        <div id='opm-select'>
          <v-select
            label='Select Level'
            :items='levels'
            :value='selectedLevel'
            @change='(lvl) => setSelectedLevel(lvl)'
            item-text="id"
            return-object
            solo
          ></v-select>
        </div>
        <LevelView class='opm-level-view' :mapConfig="mapConfig"/>
        <SnapshotOverview :loading='loadingTraces'/>
        <SessionMetrics v-if='showSessionMetrics' :loading='loadingTraces'/>
      </div>
      <div id='right-ctr'>
        <PlayerGraph :loading='loadingTraces'/>
        <PlayerSelect :loading='loadingTraces'/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LevelView from '@/components/level/LevelView.vue'
import PlayerGraph from '@/components/opm/PlayerGraph.vue'
import PlayerSelect from '@/components/opm/PlayerSelect.vue'
import SnapshotOverview from '@/components/opm/SnapshotOverview.vue'
import SessionMetrics from '@/components/opm/SessionMetrics.vue'

export default {
  name: 'OPMView',
  components: {
    LevelView,
    PlayerGraph,
    PlayerSelect,
    SessionMetrics,
    SnapshotOverview,
  },
  data() {
    return {
      mapConfig: {
        isDisabled: true,
        iconSize: 100,
        width: 600,
        height: 600
      },
      showSessionMetrics: false,
      loadingTraces: false,
    }
  },
  methods: {
    ...mapActions([
      'getSetTraces',
      'setSelectedLevel',
      'showMessage',
    ]),
    async init() {
      this.loadingTraces = true
      try {
        await this.getSetTraces()
      } catch (e) {
        this.showMessage(e)
      } finally {
        this.loadingTraces = false
      }
    },
  },
  computed: {
    ...mapGetters([
      'levels',
      'selectedLevel',
    ]),
  },
  beforeRouteEnter: (to, from, next) => {
    next(async (vm) => {
      const isAuthenticated = await vm.$api.user.isAuthenticated()
      if (!isAuthenticated) {
        vm.$router.push({ name: 'LevelSelect' })
        setTimeout(() => {
          vm.showMessage('You cannot access that page.')
        }, 200)
      }
      return true
    })
  },
  async mounted() {
    await this.init()
  },
}
</script>

<style>
  .opm-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 2rem;
    background-color: #dadada;
  }
  #opm-header {
    height: 50px;
  }
  #left-ctr {
    display: flex;
    flex-direction: column;
    width: 600px;
  }
  #right-ctr {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2rem;
    justify-content: flex-start;
  }
  .opm-header {
    margin-top: 2rem;
  }
  .opm-content {
    height: 100%;
    display: flex;
  }
  .opm-level-view {
    margin-bottom: 1rem
  }
  #opm-select {
    max-height: 50px !important;
    margin-bottom: 1rem;
  }
</style>
