<template>
  <div v-if="isReflectionDialogActive" v-show='reflectionContent' class="dialog-background">
    <div class="reflection-dialog-outer">
      <div class="reflection-dialog-inner">
        <div class="header-ctr">
          <div class="header-title capitalize">
            <v-icon v-if='isSuccessful' color='green' style='margin-right: .25em'>mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else color='red' style='margin-right: .25em'>mdi-close-circle</v-icon>
            <p>&nbsp;</p>
            {{reflectionContent.title}}
          </div>
          <div :class="subtitleClass">{{reflectionContent.subtitle}}</div>
          <div class='header-description'>{{reflectionContent.description}}</div>
        </div>
        <div class="body-ctr">
          <div  v-if='!isSuccessful' class="section-ctr">
            <h3 v-if='goals.length' class="section-title">{{reflectionContent.header}}</h3>
            <div class="goal-ctr" v-for="(goal, g) in goals" :key="`goal-${g}`">
              <div><span class="label">Title: </span>{{goal.title}}</div>
              <div class='reflection-media-ctr'><img class='reflection-media' v-if='goal.media' :src='getImgSrcStr(goal.media)'/></div>
              <div class="capitalize"><span class="label">Status: </span>{{goal.status}}</div>
              <div><span class="label">Target: </span>{{goal.target}}, <span class="label">Actual: </span>{{goal.actual}}</div>
            </div>
          </div>
          <div v-else class="section-ctr">
            <h3 v-if='reflections.length' class="section-title">{{reflectionContent.header}}</h3>
            <div class="reflections-ctr">
              <div class='reflection' v-for="(reflection, r) in reflections" :key="`reflection-${r}`">
                <div class='reflection-media-ctr'>
                  <img class='reflection-media' v-if='reflection.media' :src='getImgSrcStr(reflection)'/>
                </div>
                <div class='pr-4'>{{reflection.description}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="moves-dialog-ctr">
        <div class="moves-ctr">
          <div class="moves-title capitalize">
            {{"The System Found:"}}
            <span class="moves-inaccurate-text">
              {{getInaccurate}}
            </span>
            <span class="moves-accurate-text">
              {{getAccurate}}
            </span>
          </div>
        </div>
      </div>
      <div :style='btnCtrJustifyStyle' class='reflection-btn-ctr'>
        <button class="reflection-btn" :disabled='isTutorialActive' v-show='!isTutorialActive' @click="handleReturnToLevelSelect"><i :style='levelSelectBtnIconStyle' class="fas fa-arrow-circle-left"></i>LEVEL SELECT</button>
        <button class="reflection-btn" @click="handleReturnToLevel"><i class="fas fa-times reflection-btn-icon"></i>RETURN TO LEVEL</button>
        <button v-if="isNextLevelAvailable" class="reflection-btn" @click="handleNextLevel"><i class="fas fa-play reflection-btn-icon"></i>NEXT LEVEL</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { modes } from '../../gameLogic/boardEnums'
import { routeNames } from '../../vars'

export default {
  name: 'ReflectionDialog',
  methods: {
    ...mapActions(['deactivateReflectionDialog', 'resetSimulation']),
    handleReturnToLevelSelect() {
      this.$router.push({ name: routeNames.LEVEL_SELECT })
    },
    handleReturnToLevel() {
      this.deactivateReflectionDialog()
      this.resetSimulation()
    },
    async handleNextLevel() {
      this.deactivateReflectionDialog()
      this.resetSimulation()
      this.$router.push({ name: routeNames.LEVEL, params: { level: this.getNextLevelId() } })
    },
    getImgSrcStr(reflection) {
      return require(`../../assets/reflection/${reflection.media}.${reflection.mediaType}`)
    },
    getNextLevelId() {
      return Number(this.$route.params.level) + 1
    }
  },
  computed: {
    ...mapGetters([
      'reflectionContent',
      'isReflectionDialogActive',
      'isTutorialActive',
      'traceData',
      'linkElements',
      'contentArray',
      'initialIndex',
      'goodCount',
      'badCount'
    ]),
    isMovesDialogActive() {
      if (this.getMoveTotal > 0) {
        return true
      }
      return false
    },
    goals() {
      return this.reflectionContent.goals
    },
    subtitleClass() {
      return {
        'header-subtitle': true,
        'success-text-color': this.isSuccessful,
        'failure-text-color': !this.isSuccessful
      }
    },
    reflections() {
      return this.reflectionContent.reflections || []
    },
    isNextLevelAvailable() {
      const canAccessNextLevel = this.$api.player.canAccessLevel(this.getNextLevelId())
      return this.isSuccessful && this.isSubmission && canAccessNextLevel
    },
    isSuccessful() {
      return this.reflectionContent.status === 'success'
    },
    isSubmission() {
      return this.reflectionContent.simType === modes.SUBMIT
    },
    levelSelectBtnIconStyle() {
      return {
        'margin-right': '1rem',
        color: this.isTutorialActive ? 'grey' : '#58ba47'
      }
    },
    btnCtrJustifyStyle() {
      return {
        'justify-content': this.isTutorialActive ? 'center' : 'space-between'
      }
    },
    getMoveTotal() {
      return this.goodCount + this.badCount
    },
    getAccurate() {
      return `${this.goodCount} Accurate Moves`
    },
    getInaccurate() {
      return `${this.badCount} Inaccurate Moves`
    }
  },
}
</script>

<style scoped>
  .dialog-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }
  .reflection-dialog-outer {
    text-align: center;
    width: 70%;
    max-width: 1000px;
  }

  .reflection-dialog-inner {
    border: 3px solid black;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #444c50;
    border: 2px solid #be5e35;
    color: white;
  }

  .header-ctr {
    padding: 1rem 0;
    background-color: #353b40;
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;;
  }

  .body-ctr {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
  }
  .section-ctr {
    padding: 1rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .reflections-ctr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .reflection {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  .section-title {
    margin-bottom: 1rem;
    color: white;
  }

  .header-title {
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .header-subtitle {
    font-style: italic;
    font-weight: 700;
  }

  .goal-ctr {
    margin-bottom: 1.5rem;
  }

  .success-text-color {
    color: #58ba47;
  }

  .failure-text-color {
    color: #e52326;
  }

  .label {
    font-weight: 700;
  }

  .reflection-btn-ctr {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
  }

  .reflection-media-ctr {
    margin-right: 1rem;
  }
  .reflection-media {
    max-width: 100px;
  }

  .reflection-btn {
    background-color: #444c50;
    color: white;
    font-weight: 700;
    padding: 1rem;
  }

  .reflection-btn-icon {
    margin-right: 1rem;
    color: #58ba47;
  }
  .reflection-btn:hover {
    background-color: #89898a;
  }
  .reflection-btn[disabled] {
    color: rgb(148, 148, 148);
    pointer-events: none;
  }
  .dialog-btn {
    cursor: pointer;
  }
  .dialog-btn:hover {
    color: red;
  }
  .moves-dialog-ctr {
    background-color: #444c50;
    border: 2px solid #be5e35;
    color: white;
    margin-top: 1.5rem;
    width: 100%;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .moves-ctr {
    padding: 1rem 0;
    background-color: #353b40;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .moves-title {
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .moves-accurate-text {
    color: #58ba47;
  }
  .moves-inaccurate-text {
    color: #e52326
  }
  .waiting-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }
  .waiting-text {

  }
</style>
