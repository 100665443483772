/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
import * as d3 from 'd3'
import { simulation } from '@/gameLogic/solutionChecker'
import SimulationStep from '@/action/SimulationStep'
import MoveToCell from '@/mutation/MoveToCell'
import ChangeDirection from '@/mutation/ChangeDirection'
import Mutation from '@/mutation/Mutation'
import { logEventTypes } from '@/store/modules/logEvents'
import { actionType, elementType } from '../../vars'
import level from './level'

const startIdx = -1

const getDefaultState = () => ({
  hasTestBeenRunSuccessfully: false,
  simulationSteps: [],
  simulationType: '',
  ticks: -1,
  events: -1,
  idle: -1,
  current: startIdx,
  simulationDuration: 100,
  simulationStatus: false,
  isSimulationPaused: false,
  isSimulationWaiting: false
})

const state = getDefaultState()
const getters = {
  simulationSteps: (state) => state.simulationSteps || 0,
  numSimulationSteps: (state, getters) => getters.simulationSteps.length,
  current: (state) => state.current,
  simulationType: (state) => state.simulationType,
  ticks: (state) => state.ticks,
  events: (state) => state.events,
  idle: (state) => state.idle,
  isSimulationEmpty: (state, getters) => getters.numSimulationSteps === 0,
  simulationDuration: (state) => state.simulationDuration,
  isSimulationActive: (state) => state.simulationStatus,
  isSimulationPaused: (state) => state.isSimulationPaused,
  isSimulationWaiting: (state) => state.isSimulationWaiting,
  hasTestBeenRunSuccessfully: (state) => state.hasTestBeenRunSuccessfully
}
const actions = {
  setSimulationType: ({ commit }, type) => {
    commit('setSimulationType', type)
  },
  setTicks: ({ commit }, ticks) => {
    commit('setTicks', ticks)
  },
  setEvents: ({ commit }, events) => {
    commit('setEvents', events)
  },
  setIdle: ({ commit }, idle) => {
    commit('setIdle', idle)
  },
  // If user stops simulation, the reflection content should be destroyed
  stopSimulation: ({ dispatch, getters }) => {
    dispatch('addLogEvent', {
      type: logEventTypes.STOP_SIMULATION,
      current: getters.current,
      ticks: getters.ticks,
      events: getters.events,
      idle: getters.idle
    })
    dispatch('destroyReflection')
    dispatch('resetSimulation')
    dispatch('setTestHasBeenRun', false)
    dispatch('setBoardChanged', false)
  },
  resetSimulation: async ({ dispatch, commit }) => {
    commit('setSimulationPause', true)
    dispatch('loadElementsFromCache')
    dispatch('setCurrent', startIdx)
    dispatch('setSimulationStatus', false)
    dispatch('draw')
  },
  destroySimulation: async ({ commit }) => {
    commit('destroySimulation')
  },
  setTestHasBeenRun: ({ commit }, flag) => {
    commit('setTestHasBeenRun', flag)
  },
  initSimulation: async ({ dispatch, getters }, type) => {
    await dispatch('addLogEvent', {
      type: logEventTypes.BEGIN_SIMULATION,
      current: getters.current,
      ticks: getters.ticks,
      events: getters.events,
      idle: getters.idle
    })
    dispatch('destroySimulation')
    dispatch('setSimulationType', type)
    try {
      // TODO: get proper level data
      const boardData = await dispatch('getBoardData')
      const result = await simulation(boardData, type)
      if (result.content.status === 'success' && result.content.simType === actionType.TEST) {
        dispatch('setTestHasBeenRun', true)
        // if (getters.shouldCheckTutorialRequiredAction) {
        //   dispatch('checkTutorialRequiredAction', { type: actionType.SIMULATION_SUCCESS, target: elementType.TEST_BUTTON })
        // }
      }
      // if (result.content.status === resultType.SUCCESS && result.content.simType === actionType.TEST) {
      //   dispatch('setTestHasBeenRun', true)
      // }
      dispatch('setTicks', result.ticks)
      dispatch('setEvents', result.events)
      dispatch('setIdle', result.idle)
      dispatch('setReflectionContent', result.content)
      dispatch('setBoardChanged', false)
      // dispatch('setTrace', 'traceThing')
      const simulationSteps = result.actions.map((actionData) => new SimulationStep(actionData))
      dispatch('setSimulationSteps', simulationSteps)
    } catch (err) {
      dispatch('showMessage', err)
    }
  },
  runSimulation: async ({ dispatch }, type) => {
    await dispatch('enableLoadingDialog')
    await dispatch('initSimulation', type)
    dispatch('cacheElements')
    await dispatch('disableLoadingDialog')
    dispatch('setSimulationStatus', true)
    await dispatch('stepForward')
  },
  stepForward: async ({ getters, dispatch, commit, rootState }) => {
    // LOGEVENT TIMESLIDER
    commit('incrementCurrent')
    const step = getters.simulationSteps[getters.current]
    const stepComponent = step.component
    let stepMutations = step.mutations
    let element = getters.selectByID(stepComponent.id)
    // If the element is not found, create it
    if (!element) {
      const componentData = {
        id: stepComponent.id,
        type: stepComponent.type,
        transform: getters.transform,
        scale: getters.scale.board,
      }
      // By convention, all values in mutations array are the values needed to create the element itself
      stepMutations.forEach((mut) => {
        componentData[mut.key] = mut.value
      })
      // TODO: confirm no threads are ever added during playback
      // Do not add threads during playback, there is never a reason to
      if (componentData.type !== 'thread') {
        await dispatch('createLoadImageElements', { elementData: [componentData], isBoardElement: true })
      }
      element = getters.selectByID(componentData.id)
      // If created, element does not need to be mutated, so remove mutations from array
      stepMutations = []
    }
    // TODO: This should be done in the create simulation step
    const mutationsArr = stepMutations.map((mut) => {
      const mutationData = {
        element,
        key: mut.key,
        value: mut.value,
        duration: getters.simulationDuration
      }
      if (mut.key === 'cell') {
        return new MoveToCell(mutationData)
      }
      if (mut.key === 'direction') {
        return new ChangeDirection(mutationData)
      }
      return new Mutation(mutationData)
    })

    const timer = d3.timer(async (elapsed) => {
      let t = 1
      const immediate = false
      if (!immediate) {
        t = Math.min(1, (elapsed / getters.simulationDuration))
      }
      mutationsArr.forEach((mut) => {
        mut.mutate(elapsed)
        // If mutation delivers a package successfully, get rid of package
        if (mut.key === 'delivered' && mut.value === true && element.type === elementType.PACKAGE) {
          // this.elements = this.elements.filter((el) => el !== element)
          if (getters.selectByID(element.id)) {
            dispatch('removeElement', (element))
          }
        }
      })
      // TODO: do you want mutations?
      // dispatch('addLogEvent', { type: logEventTypes.MUTATE_ELEMENT, element, mutations: mutationsArr })
      dispatch('draw')

      // if this animation is over
      if (t === 1) {
        // stop this timer for this layout and start a new one
        timer.stop()
        if (getters.isSimulationPaused) return
        if (getters.current < getters.numSimulationSteps - 1) {
          dispatch('stepForward')
          return
        }
        dispatch('setSimulationStatus', false)
        await dispatch('addLogEvent', {
          type: logEventTypes.FINISH_SIMULATION,
          current: getters.current,
          ticks: getters.ticks,
          events: getters.events,
          idle: getters.idle
        })
        if (getters.shouldCheckTutorialRequiredAction) {
          const checkType = getters.reflectionContent.status === 'success' ? actionType.SIMULATION_SUCCESS : actionType.SIMULATION_FAILURE
          dispatch('checkTutorialRequiredAction', { type: checkType, target: getters.simulationType })
          // return
        }
        // TODO: is this the data that you want?
        commit('setSimulationWaiting', true)
        dispatch('waitForward')
      }
    })
  },
  waitForward: async ({ getters, dispatch, commit, rootState }) => {
    const timer = d3.timer(async (elapsed) => {
      if (elapsed >= 500) {
        // stop this timer for this layout and start a new one
        timer.stop()
        if (rootState.level.retrieving === false) {
          commit('setSimulationWaiting', false)
          // dispatch('buildCommunityContent')
          dispatch('activateReflectionDialog')
          const data = await dispatch('getBoardSnapshotData')
          dispatch('addLogEvent', { type: logEventTypes.BOARD_SNAPSHOT, board: data })
        } else {
          dispatch('waitForward')
        }
      }
    })
  },
  setSimulationSteps: ({ commit }, steps) => {
    commit('setSimulationSteps', steps)
  },
  setCurrent: ({ commit }, num) => {
    commit('setCurrent', num)
  },
  setSimulationStatus({ commit }, flag) {
    commit('setSimulationStatus', flag)
  },
  pauseSimulation: async ({ commit, dispatch, getters }) => {
    dispatch('addLogEvent', {
      type: logEventTypes.PAUSE_SIMULATION,
      current: getters.current,
      ticks: getters.ticks,
      events: getters.events,
      idle: getters.idle
    })
    commit('setSimulationPause', true)
  },
  unpauseSimulation: async ({ commit, dispatch }) => {
    commit('setSimulationPause', false)
    dispatch('stepForward')
  },
  skipSimulation: async ({ commit, dispatch, getters }) => {
    if (getters.isSimulationPaused) {
      await dispatch('unpauseSimulation')
    }
    const defaultSkipStep = 2
    dispatch('addLogEvent', {
      type: logEventTypes.SKIP_SIMULATION,
      current: getters.current,
      ticks: getters.ticks,
      events: getters.events,
      idle: getters.idle
    })
    if (getters.numSimulationSteps >= defaultSkipStep) {
      commit('setCurrent', getters.numSimulationSteps - defaultSkipStep)
    }
    // dispatch('stepForward')
  }
}
const mutations = {
  setCurrent(state, num) {
    state.current = num
  },
  setSimulationType(state, type) {
    state.simulationType = type
  },
  setSimulationSteps(state, simulationSteps) {
    state.simulationSteps = simulationSteps
  },
  setTicks(state, ticks) {
    state.ticks = ticks
  },
  setEvents(state, events) {
    state.events = events
  },
  setIdle(state, idle) {
    state.idle = idle
  },
  incrementCurrent(state) {
    state.current += 1
  },
  destroySimulation(state) {
    Object.assign(state, getDefaultState())
  },
  setSimulationStatus(state, flag) {
    state.simulationStatus = flag
  },
  setSimulationPause(state, flag) {
    state.isSimulationPaused = flag
  },
  setSimulationWaiting(state, flag) {
    console.log(`setSimulationWaiting: ${flag}`)
    state.isSimulationWaiting = flag
  },
  setTestHasBeenRun(state, flag) {
    state.hasTestBeenRunSuccessfully = flag
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
