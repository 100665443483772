<template>
  <v-card :loading='loading'>
    <v-card-title class='opm-view-card-title'>Snapshot Overview</v-card-title>
    <v-card-text class='opm-view-card-content'>
      <v-row>
        <v-col class='pb-0'><span class='snapshot-datum'>Selected Player:</span> {{selectedPlayerString}}</v-col>
        <v-col class='pb-0'><span class='snapshot-datum'>Efficiency:</span>  {{ticks}}</v-col>
        <v-col class='pb-0'><span class='snapshot-datum'>Submission Result:</span>  {{submissionResult}}</v-col>
      </v-row>
      <v-row>
        <v-col><span class='snapshot-datum'>Moving Connected Elements:</span>  {{movingConnectedElements}}</v-col>
        <v-col><span class='snapshot-datum'>Moving Elements Same Zone:</span>  {{movingElementsInSameZone}}</v-col>
        <v-col><span class='snapshot-datum'>Same Zone Linking:</span>  {{sameZoneLinking}}</v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

const NONE = 'None'
export default {
  name: 'SnapshotOverview',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['selectedLogIdIndex', 'selectedSnapshot']),
    id() {
      return this.selectedSnapshot.id || NONE
    },
    ticks() {
      return this.selectedSnapshot.ticks
    },
    submissionResult() {
      return this.selectedSnapshot.submission_result || NONE
    },
    movingConnectedElements() {
      return this.selectedSnapshot.moving_connected_elements || NONE
    },
    movingElementsInSameZone() {
      return this.selectedSnapshot.no_order_change_behaviour_issue || NONE
    },
    sameZoneLinking() {
      return this.selectedSnapshot.same_zone_linking || NONE
    },
    selectedPlayerString() {
      const formattedIndex = this.selectedLogIdIndex !== null ? this.selectedLogIdIndex + 1 : 1
      return `Player ${formattedIndex}`
    }
  },
}
</script>

<style>
  .snapshot-datum {
    font-weight: 700;
  }
</style>
