<template>
  <div class="ls-background">
      <div v-if='!isLoading' class="ls-outer">
        <div class="ls-header">
          <div class="selected-level-ctr">Selected Level: <span class="selected-level-number">{{selectedLevelString}}</span></div>
          <v-btn :disabled='!selectedLevelString' @click="loadLevel" color='primary'>Load Level</v-btn>
        </div>
        <div class='ls-inner'>
          <div class='left-ctr'>
            <div class="levels-ctr">
              <div v-for='level in levels' @click.prevent="setSelectedLevel(level)" :class="getLevelIconClass(level)" :key='level.id'>
                <div :class="getLevelLabelClass(level)">{{level.id}}</div>
              </div>
            </div>
            <v-btn id='opm-btn' :disabled='!$api.user.isAuthenticated()' color='warning' @click.prevent='navigateToOPM'>OPM</v-btn>
            <v-btn id='newopm-btn' :disabled='!$api.user.isAuthenticated()' color='warning' @click.prevent='navigateToOPMTwoByTwo'>New OPM</v-btn>
          </div>
          <LevelView :selectedLevelString='selectedLevelString'/>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint-disable radix */
import { mapActions, mapGetters } from 'vuex'
import LevelView from '@/components/level/LevelView.vue'
import aws from '@/auth/aws'
import { routeNames } from '../../vars'

export default {
  name: 'LevelSelect',
  components: {
    LevelView
  },
  data() {
    return {
      isLoading: false,
      canvasKey: 1,
      userProfile: {},
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog', 'setSelectedLevel', 'initLevels', 'setSelectedLevel']),
    async init() {
      let activityData = this.$api.storage.getItem('Activity')
      if (!activityData) {
        if (this.$api.user.isAuthenticated()) {
          try {
            activityData = await aws.getCurrentActivity().then((res) => res.data.activity)
          } catch (error) {
            this.showMessage('An error occurred while retrieving activity data. Loading default activity...')
          }
        }
        if (!activityData) {
          activityData = require('@/data/defaultActivityData.json').activity
        }
      }
      this.$api.storage.setItem('Activity', activityData)
      this.initLevels()
    },
    navigateToOPM() {
      this.$router.push({ name: 'ViewPortOPM' })
    },
    navigateToOPMTwoByTwo() {
      this.$router.push({ name: 'OPMTwoByTwo' })
    },
    loadLevel() {
      this.rtr.push({ name: routeNames.LEVEL, params: { level: this.selectedLevelString } })
    },
    getLevelIconClass(level) {
      return {
        'level-icon': true,
        'enabled-icon': level.isAccessible,
        'selected-icon': this.selectedLevel ? this.selectedLevel.id === level.id : false
      }
    },
    getLevelLabelClass(level) {
      return {
        'icon-label': true,
        'no-select': true,
        'enabled-label': level.isAccessible
      }
    }
  },
  computed: {
    ...mapGetters(['levels', 'selectedLevelString']),
  },
  mounted() {
    this.isLoading = true
    this.enableLoadingDialog()
    this.init().then(() => {
      this.isLoading = false
      this.disableLoadingDialog()
    })
  }
}
</script>

<style lang='scss'>

$base-padding: 10px;
$border-color: var(--secondary);

.ls-background {
  width: 100%;
  height: 100%;
  background-color: #415054;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#opm-btn {
  position: absolute;
  margin-top: 1rem;
  width: 100px;
}
#newopm-btn {
  position: absolute;
  margin-top: 1rem;
  margin-left: 10rem;
  width: 100px;
}

.ls-outer {
  width: 800px;
  display: flex;
  flex-direction: column;
}

.ls-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: $base-padding * 2;
}

.selected-level-ctr {
  color: white;
  font-weight: 700;
  margin-right: 2rem;
}

.selected-level-number {
  font-size: 1.5rem;
}

.ls-inner {
  display: flex;
  justify-content: space-between;
  min-width: 800px;
}

.levels-ctr {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.level-icons-ctr {
  display: flex;
  flex-wrap: wrap;
}

.level-icon {
  width: 65px;
  height: 62px;
  border: 2px solid grey;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enabled-icon {
  border: 2px solid var(--secondary);
  cursor: pointer;
}

.selected-icon {
  background-color: var(--secondary);
}

.enabled-icon:hover {
  background-color: var(--secondary);
}

.icon-label {
  font-weight: 700;
  color: grey;
  font-size: 1.5rem;
  text-align: center;
}

.enabled-label {
  color: white;
}

.select-title {
  color: white;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
}

.add-level-button {
  background-color: #be5e35;
  width: 20px;
  height: 20px;
  padding: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

</style>
